import React from 'react';
import CountdownTimer from './CountdownTimer';
import { Link } from "react-router-dom";
import '../css/Countdown.css';
function Countdown() {
  //const toMilliseconds = (hrs,min,sec) => (hrs*60*60+min*60+sec)*1000;
  // const THREE_DAYS_IN_MS = 1 * 24 * 60 * 60 * 1000;
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const a = new Date(); // Current date now.
  const dd = a.getDate() + 1
  const mm = months[a.getMonth()]
  const b = new Date(`${mm} ${dd} 2023 09:30:00`); // DRAW DATE. Military time
  const d = (b-a);
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + d;

  return (
    <>
      <div className='countdownHolder'>
        <div className='div-table'>
          <div className='div-table-row'>
            <div className='div-table-col-header'>
            COUNTDOWN TO OUR NEXT DRAW
            </div>
          </div>
        </div>
        <CountdownTimer targetDate={dateTimeAfterThreeDays} />
        <div className='div-table'>
        <div className='div-table-row'>
            <div className='div-table-col3'>
            hours
            </div>
            <div className='div-table-col3'>
            minutes
            </div>
            <div className='div-table-col3'>
            seconds
            </div>
          </div>
        </div>
        <div className='countdown-text-holder'>Feeling lucky tonight? Place your bets now before you run out of time</div>
        <div className='countdown-text-holder'>
        <Link to='/play'><button className='pill-button'>PLAY NOW</button></Link>
        </div>
        {/* <div>COUNTDOWN TO OUR NEXT DRAW</div>
        <CountdownTimer targetDate={dateTimeAfterThreeDays} /> */}
      </div>
    </>
  )
}

export default Countdown