import React, { useState, useEffect } from 'react';
// import { Button } from './Button';
import { Link, useLocation } from 'react-router-dom';
import '../css/Navbar.css';
import Login from '../pages/Login';
import NavProfile from './NavProfile';
import ForgotPassword from '../pages/ForgotPassword';
import {Domain_Url} from '../mocks/Constants';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  const [count, setCount] = useState(0);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);

  useEffect(() => {
    setCount((count) => count + 1);
    if (count > 1) {
      setShowLogin(true);
    } else {
      setShowLogin(false);
    }
  }, [showLogin])

  const handleShowLogin = () => {
    if (showLogin) {
      setShowLogin(false);
    }
    else {
      setShowLogin(true);
    }
  }

  const handleShowForgot = () => {
    if (showForgot) {
      setShowForgot(false);
    }
    else {
      setShowForgot(true);
    }
  }

  window.addEventListener('resize', showButton);

  function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  function openNav() {
    document.getElementById("mySidenav").style.width = '100%';
  }
  
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  const {pathname} = useLocation();
  var showme = false;
  if (pathname === '/sign-up') {
    showme = true;
  } else {
    showme = false;
  }

  const token = localStorage.getItem("token");
  return (
    <>
      <div className='navContainer'>
        <div className='topnav' id='myTopnav'>
          {/* <Link to='/'><div className='logo'></div><span className='logo-text'>Lucky Giving</span></Link> */}
          <Link to='/'><img src={Domain_Url.Localhost+'/images/logo.png'} height='30'/></Link>
          <Link to='/play' className={(pathname === '/play') ? 'active' : 'nav-links'} onClick={closeMobileMenu}>GAMES</Link>
          <Link to='/projects' className={(pathname === '/projects') ? 'active' : 'nav-links'} onClick={closeMobileMenu}>PROJECTS</Link>
          <Link to='/draw' className={(pathname === '/draw') ? 'active' : 'nav-links'} onClick={closeMobileMenu}>DRAW</Link>
          {token && <Link to='/winnings' className={(pathname === '/winnings') ? 'active' : 'nav-links'} onClick={closeMobileMenu}>WINNINGS</Link>}
          {token ? <NavProfile />:
            <div>
            <div className={showme ? 'displayLoginTrue' : 'displayLoginFalse'}>
              <div className='nav-links-rounded' onClick={event =>  window.location.href='/sign-up'}>
                JOIN
              </div>
            </div>
            <div className={showme ? 'displayLoginFalse' : 'displayLoginTrue'}>
              <div className='nav-links-rounded' onClick={handleShowLogin}>
                LOGIN
              </div>
            </div>
            </div>
          }
          <Link className='icon' onClick={openNav}>
          &#9776;
          </Link>
        </div>
      
        <div id='mySidenav' className='sidenav'>
          <Link className='closebtn' onClick={closeNav}>&times;</Link>
          <Link to='/play' className={(pathname === '/play') ? 'active' : 'nav-links'} onClick={closeNav}>GAMES</Link>
          <Link to='/projects' className={(pathname === '/projects') ? 'active' : 'nav-links'} onClick={closeNav}>PROJECTS</Link>
          <Link to='/draw' className={(pathname === '/draw') ? 'active' : 'nav-links'} onClick={closeNav}>DRAW</Link>
        </div>
      </div>
      <Login isOpen={showLogin} onRequestClose={handleShowLogin} title="WELCOME BACK"/>
    </>
  );
}

export default Navbar;
