import React,{ useEffect, useState, useRef }  from 'react'
import { useParams, Link } from "react-router-dom";
import '../css/Play.css'
import {Domain_Url} from '../mocks/Constants';
import JsonData from '../mocks/projects.json';
import axios from "axios";
import Alert from '../pages/Alert';

function PlayBetArea() {
    const [isPlusSelected0, setIsPlusSelected0] = useState(false);
    const [isPlusSelected1, setIsPlusSelected1] = useState(false);
    const [isPlusSelected2, setIsPlusSelected2] = useState(false);
    const [isPlusSelected3, setIsPlusSelected3] = useState(false);
    const [isPlusSelected4, setIsPlusSelected4] = useState(false);
    const [isPlusSelected5, setIsPlusSelected5] = useState(false);
    const [isPlusSelected6, setIsPlusSelected6] = useState(false);
    const [isPlusSelected7, setIsPlusSelected7] = useState(false);
    const [isPlusSelected8, setIsPlusSelected8] = useState(false);
    const [isPlusSelected9, setIsPlusSelected9] = useState(false); 
    const [isBoosted, setIsBoosted] = useState(false);
    const [price, setPrice] = useState(5)
    const [total, setTotal] = useState(0)
    const [idList, setIdList] = useState([])
    const [plusOne, setPlusOne] = useState();
    const [entryList, setEntryList] = useState([])
    const [arrayEntry, setArrayEntry] = useState([])
    const [mHeight, setmHeight] = useState(60)
    const [isEdit, setIsEdit] = useState(false);
    const [isEditSave, setIsEditSave] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [editIndex, setEditIndex] = useState("")
    const [editArrayEntry, setEditArrayEntry] = useState([])
    const [isEditEntry, setIsEditEntry] = useState(false)
    const [isSelected1, setIsSelected1] = useState(false);
    const [isSelected2, setIsSelected2] = useState(false);
    const [isSelected3, setIsSelected3] = useState(false);
    const [isSelected4, setIsSelected4] = useState(false);
    const [isSelected5, setIsSelected5] = useState(false);
    const [isSelected6, setIsSelected6] = useState(false);
    const [isSelected7, setIsSelected7] = useState(false);
    const [isSelected8, setIsSelected8] = useState(false);
    const [isSelected9, setIsSelected9] = useState(false);
    const [isSelected10, setIsSelected10] = useState(false);
    const [isSelected11, setIsSelected11] = useState(false);
    const [isSelected12, setIsSelected12] = useState(false);
    const [isSelected13, setIsSelected13] = useState(false);
    const [isSelected14, setIsSelected14] = useState(false);
    const [isSelected15, setIsSelected15] = useState(false);
    const [isSelected16, setIsSelected16] = useState(false);
    const [isSelected17, setIsSelected17] = useState(false);
    const [isSelected18, setIsSelected18] = useState(false);
    const [isSelected19, setIsSelected19] = useState(false);
    const [isSelected20, setIsSelected20] = useState(false);
    const [isSelected21, setIsSelected21] = useState(false);
    const [isSelected22, setIsSelected22] = useState(false);
    const [isSelected23, setIsSelected23] = useState(false);
    const [isSelected24, setIsSelected24] = useState(false);
    const [isSelected25, setIsSelected25] = useState(false);
    const [isSelected26, setIsSelected26] = useState(false);
    const [isSelected27, setIsSelected27] = useState(false);
    const [isSelected28, setIsSelected28] = useState(false);
    const [isSelected29, setIsSelected29] = useState(false);
    const [isSelected30, setIsSelected30] = useState(false);
    const [isSelected31, setIsSelected31] = useState(false);
    const [isSelected32, setIsSelected32] = useState(false);
    const [isSelected33, setIsSelected33] = useState(false);
    const [isSelected34, setIsSelected34] = useState(false);
    const [isSelected35, setIsSelected35] = useState(false);
    const [isSelected36, setIsSelected36] = useState(false);
    const [isSelected37, setIsSelected37] = useState(false);
    const [isSelected38, setIsSelected38] = useState(false);
    const [isSelected39, setIsSelected39] = useState(false);
    const [isSelected40, setIsSelected40] = useState(false);
    const [isSelected41, setIsSelected41] = useState(false);
    const [isSelected42, setIsSelected42] = useState(false);
    const [isSelected43, setIsSelected43] = useState(false);
    const [isSelected44, setIsSelected44] = useState(false);
    const [isSelected45, setIsSelected45] = useState(false);
    const [isProjectSelected, setIsProjectSelected] = useState(false);
    const [supported_project_id, setSupportedProject] = useState("0");
    const [supported_project_name, setSupportedProjectName] = useState("");
    const projectArray = []
    const [isLuckypick, setLuckyPick] = useState(false);
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("userId");
    const [count, setCount] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [alertImage, setAlertImage]= useState("./images/red-check.png")
    const [alertTitle, setAlertTitle] = useState("SUCCESSFUL")
    const [alertMessage, setAlertMessage] = useState("This is for testing purposes only and is not an official entry.")
    
    useEffect(() => {
        setCount((count) => count + 1);
        if (count > 1) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
      }, [showAlert])

      const handleShowAlert = () => {
        if (showAlert) {
            setShowAlert(false);
        }
        else {
            setShowAlert(true);
        }
      }

    const getProjects = () => {
      JsonData.map((item) => {
        projectArray.push(<div className={isProjectSelected ? "projectPlayHolderSelected" : "projectPlayHolder"} id={'project'+item.id}>
          <button onClick={handleSelectedProject} className='projectButton'><img src={Domain_Url.Localhost+item.src} className={isProjectSelected ? "play-project-image-selected" : "play-project-image"} id={item.id} title={item.label}/></button>
          <p className='play-project-label'>{item.label}</p>
          </div>)
      })

      return projectArray
    }

    const resetProjectSelected = () => {
      JsonData.map((item) => {
        if(item.id) {
          document.getElementById('project'+item.id).className = 'projectPlayHolder'
          if (!isEdit) {
          setSupportedProject("0")
          setSupportedProjectName("")
          }
        }
      })
    }

    //click numbers
    const handleClick = (e) => {
      if (editIndex != "") { 
        //alert(e.target.className)
        if(e.target.className==='numButtonSelected'){
          
          var index = editArrayEntry.indexOf(e.target.value);
          var mynewArr = editArrayEntry.splice(index, 1);
          e.target.className='numButton'
           // e.target.click()
           setEditArrayEntry(editArrayEntry => [...editArrayEntry])
          //clickMyNumber(e.target.value)
          console.log("Already selected from previous", e.target.value)
        } else {
          if (editArrayEntry.length < 6) {
              
               clickMyNumber(e.target.value)
               setEditArrayEntry(editArrayEntry => [...editArrayEntry,e.target.value])
          }
        }
      }
      else {
        if(e.target.className==='numButtonSelected'){
          var index = idList.indexOf(e.target.value);
          idList.splice(index, 1);
          //e.target.className='numButton'
          clickMyNumber(e.target.value)
        } else {
          if (idList.length < 6) {
              
              clickMyNumber(e.target.value)
              setIdList([...idList, e.target.value])
          }
        }
      }
      
    
    }
    
    const clickMyNumber = (num) => {
      setLuckyPick(false)
      switch(parseInt(num)) {
        case 1:
          isSelected1 ?  setIsSelected1(false) : setIsSelected1(true)
          break   
        case 2:
          isSelected2 ?  setIsSelected2(false) : setIsSelected2(true)
          break 
        case 3:
          isSelected3 ?  setIsSelected3(false) : setIsSelected3(true)
          break 
        case 4:
          isSelected4 ?  setIsSelected4(false) : setIsSelected4(true)
          break
        case 5:
          isSelected5 ?  setIsSelected5(false) : setIsSelected5(true)
          break
        case 6:
            isSelected6 ?  setIsSelected6(false) : setIsSelected6(true)
            break
        case 7:
            isSelected7 ?  setIsSelected7(false) : setIsSelected7(true)
            break
        case 8:
            isSelected8 ?  setIsSelected8(false) : setIsSelected8(true)
            break
        case 9:
            isSelected9 ?  setIsSelected9(false) : setIsSelected9(true)
            break
        case 10:
            isSelected10 ?  setIsSelected10(false) : setIsSelected10(true)
            break
          case 11:
              isSelected11 ?  setIsSelected11(false) : setIsSelected11(true)
              break
          case 12:
              isSelected12 ?  setIsSelected12(false) : setIsSelected12(true)
              break
          case 13:
              isSelected13 ?  setIsSelected13(false) : setIsSelected13(true)
              break
          case 14:
              isSelected14 ?  setIsSelected14(false) : setIsSelected14(true)
              break
          case 15:
              isSelected15 ?  setIsSelected15(false) : setIsSelected15(true)
              break
          case 16:
              isSelected16 ?  setIsSelected16(false) : setIsSelected16(true)
              break
          case 17:
              isSelected17 ?  setIsSelected17(false) : setIsSelected17(true)
              break
          case 18:
              isSelected18 ?  setIsSelected18(false) : setIsSelected18(true)
              break
          case 19:
              isSelected19 ?  setIsSelected19(false) : setIsSelected19(true)
              break
          case 20:
              isSelected20 ?  setIsSelected20(false) : setIsSelected20(true)
              break
          case 21:
              isSelected21 ?  setIsSelected21(false) : setIsSelected21(true)
              break
          case 22:
              isSelected22 ?  setIsSelected22(false) : setIsSelected22(true)
              break
          case 23:
              isSelected23 ?  setIsSelected23(false) : setIsSelected23(true)
              break
          case 24:
              isSelected24 ?  setIsSelected24(false) : setIsSelected24(true)
              break
          case 25:
              isSelected25 ?  setIsSelected25(false) : setIsSelected25(true)
              break
          case 26:
              isSelected26 ?  setIsSelected26(false) : setIsSelected26(true)
              break
          case 27:
              isSelected27 ?  setIsSelected27(false) : setIsSelected27(true)
              break
          case 28:
              isSelected28 ?  setIsSelected28(false) : setIsSelected28(true)
              break
          case 29:
              isSelected29 ?  setIsSelected29(false) : setIsSelected29(true)
              break
          case 30:
              isSelected30 ?  setIsSelected30(false) : setIsSelected30(true)
              break
          case 31:
              isSelected31 ?  setIsSelected31(false) : setIsSelected31(true)
              break
          case 32:
              isSelected32 ?  setIsSelected32(false) : setIsSelected32(true)
              break
          case 33:
              isSelected33 ?  setIsSelected33(false) : setIsSelected33(true)
              break
          case 34:
              isSelected34 ?  setIsSelected34(false) : setIsSelected34(true)
              break
          case 35:
              isSelected35 ?  setIsSelected35(false) : setIsSelected35(true)
              break
          case 36:
              isSelected36 ?  setIsSelected36(false) : setIsSelected36(true)
              break
          case 37:
              isSelected37 ?  setIsSelected37(false) : setIsSelected37(true)
              break
          case 38:
              isSelected38 ?  setIsSelected38(false) : setIsSelected38(true)
              break
          case 39:
              isSelected39 ?  setIsSelected39(false) : setIsSelected39(true)
              break
          case 40:
              isSelected40 ?  setIsSelected40(false) : setIsSelected40(true)
              break
          case 41:
              isSelected41 ?  setIsSelected41(false) : setIsSelected41(true)
              break
          case 42:
              isSelected42 ?  setIsSelected42(false) : setIsSelected42(true)
              break
          case 43:
              isSelected43 ?  setIsSelected43(false) : setIsSelected43(true)
              break
          case 44:
              isSelected44 ?  setIsSelected44(false) : setIsSelected44(true)
              break
          case 45:
              isSelected45 ?  setIsSelected45(false) : setIsSelected45(true)
              break
        default:
          return 'foo';
      }
    }

    const handleClickPlusOne = (e) => {
        clearPlusOne()
        console.log("Plus one selected", e.target.value)
        console.log("Plus one selected class", e.target.className)
        console.log("Plus one is Edit", isEdit)
        
          switch(parseInt(e.target.value)) {
            case 1:
              if (isPlusSelected1) {
                setIsPlusSelected1(false)
                setPlusOne("");
              } else {
                 setIsPlusSelected1(true)
                 setPlusOne(e.target.value);
              }
              break   
            case 2:
              if (isPlusSelected2) {
                setIsPlusSelected2(false)
                setPlusOne("");
              } else {
                setIsPlusSelected2(true)
                setPlusOne(e.target.value);
              } 
              break 
            case 3:
              if (isPlusSelected3) { 
                 setIsPlusSelected3(false) 
                 setPlusOne("");
               } else { 
                setIsPlusSelected3(true)
                setPlusOne(e.target.value);
               }
              break 
            case 4:
              if (isPlusSelected4) {
                  setIsPlusSelected4(false) 
                  setPlusOne("");
               } else { 
                setIsPlusSelected4(true)
                setPlusOne(e.target.value);
               }
              break
            case 5:
              if (isPlusSelected5) {
                  setIsPlusSelected5(false) 
                  setPlusOne("");
               } else { 
                setIsPlusSelected5(true)
                setPlusOne(e.target.value);
              }
              break
            case 6:
              if (isPlusSelected6) {  
                setIsPlusSelected6(false)
                setPlusOne("");
               } else { 
                setIsPlusSelected6(true)
                setPlusOne(e.target.value);
               }
                break
            case 7:
              if (isPlusSelected7) {
                  setIsPlusSelected7(false) 
                  setPlusOne("");
                } else { 
                  setIsPlusSelected7(true)
                  setPlusOne(e.target.value);
                  }
                break
            case 8:
              if (isPlusSelected8) {  
                setIsPlusSelected8(false) 
                setPlusOne("");
              } else{
                setPlusOne(e.target.value);
                setIsPlusSelected8(true) }
                break
            case 9:
              if(isPlusSelected9 )  {
                setIsPlusSelected9(false) 
                setPlusOne("");
              } else { 
                setIsPlusSelected9(true)
                setPlusOne(e.target.value);
              }
                break
            default:
              if(isPlusSelected0 ) {  
                setIsPlusSelected0(false)
                setPlusOne("");
              } else { 
                setIsPlusSelected0(true)
                setPlusOne(e.target.value);
              }
              break
          }
        
    }
    const handleClearSelectionTapped = () => {
      resetProjectSelected()
      handleClearSelection()
    }
    //Clear selection 
     const handleClearSelection = () => {
        //if(idList.length > 1) 
        //clear plus+1
        setIsPlusSelected0(false)
        setIsPlusSelected1(false)
        setIsPlusSelected2(false)
        setIsPlusSelected3(false)
        setIsPlusSelected4(false)
        setIsPlusSelected5(false)
        setIsPlusSelected6(false)
        setIsPlusSelected7(false)
        setIsPlusSelected8(false)
        setIsPlusSelected9(false)
        //clear numselected
        setIsSelected1(false)
        setIsSelected2(false)
        setIsSelected3(false)
        setIsSelected4(false)
        setIsSelected5(false)
        setIsSelected6(false)
        setIsSelected7(false)
        setIsSelected8(false)
        setIsSelected9(false)
        setIsSelected10(false)
        setIsSelected11(false)
        setIsSelected12(false)
        setIsSelected13(false)
        setIsSelected14(false)
        setIsSelected15(false)
        setIsSelected16(false)
        setIsSelected17(false)
        setIsSelected18(false)
        setIsSelected19(false)
        setIsSelected20(false)
        setIsSelected21(false)
        setIsSelected22(false)
        setIsSelected23(false)
        setIsSelected24(false)
        setIsSelected25(false)
        setIsSelected26(false)
        setIsSelected27(false)
        setIsSelected28(false)
        setIsSelected29(false)
        setIsSelected30(false)
        setIsSelected31(false)
        setIsSelected32(false)
        setIsSelected33(false)
        setIsSelected34(false)
        setIsSelected35(false)
        setIsSelected36(false)
        setIsSelected37(false)
        setIsSelected38(false)
        setIsSelected39(false)
        setIsSelected40(false)
        setIsSelected41(false)
        setIsSelected42(false)
        setIsSelected43(false)
        setIsSelected44(false)
        setIsSelected45(false)
        //}

        setIdList([])
      
        if (supported_project_id === 0 || supported_project_id === "") {
          resetProjectSelected()
        }
        //alert(isLuckypick)
        // if(!isLuckypick) {
        //   resetProjectSelected()
        // }
        setIsBoosted(false)
      
     }

     const clearPlusOne = () => {
        setIsPlusSelected0(false)
        setIsPlusSelected1(false)
        setIsPlusSelected2(false)
        setIsPlusSelected3(false)
        setIsPlusSelected4(false)
        setIsPlusSelected5(false)
        setIsPlusSelected6(false)
        setIsPlusSelected7(false)
        setIsPlusSelected8(false)
        setIsPlusSelected9(false)

        
     }

     const hanldeSaveSelection = () => {
      
      // else {

      if (editIndex !== "")//(document.getElementById('editInputIndex').value !== "") 
      {
        if (editArrayEntry.length < 6) {
          setAlertImage("./images/red-x.png")
          setAlertTitle("FAILED")
          setAlertMessage("Please select 6 numbers")
          handleShowAlert()
          return false
        }
        else if (plusOne === undefined || plusOne === null || plusOne === "") {
          setAlertImage("./images/red-x.png")
          setAlertTitle("FAILED")
          setAlertMessage("Please select your +1 digit")
          handleShowAlert()
          return false
        }
        else {
          console.log("save entires",entryList)
          console.log("save array",editArrayEntry)
            entryList.map((item, index) => {
        
              if(index === parseInt(document.getElementById('editInputIndex').value)) {
              //Update entries
              item[0].entries = ""
              item[0].entries = editArrayEntry
              item[0].plusOne = plusOne
              item[0].isBoosted = isBoosted
              item[0].ticketPrice = price
              item[0].isLuckypick = isLuckypick
              item[0].supported_project_id = supported_project_id
              item[0].supported_project_name = supported_project_name
            }
            
          })

          console.log("Save entrylist", entryList)
          //document.getElementById('editInputIndex').value = ""
          // clear to be able to pick again
          setEditIndex("")
          setPlusOne("")
          
          setIsEditSave(true)
          setIsBoosted(false)
          resetProjectSelected()
        }
        
      
      }
       else 
       {
      //check if login
      //if not show login
      //else 
      //alert(plusOne)
      // if(idList.length > 0) {
      //   alert(idList.length)
      //   alert(plusOne.length)
      //   setIdList(idList => [...idList, plusOne])
      // }
        if(supported_project_id < 1) {
          setAlertImage("./images/red-x.png")
          setAlertTitle("FAILED")
          setAlertMessage("Select a project to support before playing")
          handleShowAlert()
        }
        else if (idList.length < 6) {
          setAlertImage("./images/red-x.png")
          setAlertTitle("FAILED")
          setAlertMessage("Please select 6 numbers")
          handleShowAlert()
        }
        else if (plusOne === undefined || plusOne === null || plusOne === "") {
          setAlertImage("./images/red-x.png")
          setAlertTitle("FAILED")
          setAlertMessage("Please select your +1 digit")
          handleShowAlert()
        }
        else {
          setIdList(idList => [...idList, plusOne])
          setEntryList([...entryList, [{"entries": idList,"plusOne":plusOne,"ticketPrice":price,"isBoosted":isBoosted,"isLuckypick":isLuckypick,"supported_project_id":supported_project_id,"supported_project_name":supported_project_name}]])
          setIsBoosted(false)
          resetProjectSelected()
          setPlusOne("")
        }
      }
    // }
  }

     //get user entries
     const getEntry = () => {
      console.log("Retrieving entries", entryList)
      var mEntry = entryList
      document.getElementById('entiresHolder').innerHTML = ""
      let mTotal = 0
      var xy = 0
    
  mEntry.map((item, index) => {
        {
          item[0].entries.map((entry,index) => {
            if(index === 0) {
              setArrayEntry(arrayEntry => [...arrayEntry,<div className='play-project-title'>{item[0].supported_project_name}</div>])
            } 
              setArrayEntry(arrayEntry => [...arrayEntry,<button className="numButtonSelected">{entry}</button>])
            
            
        
          // setEditArrayEntry(editArrayEntry => [...editArrayEntry,entry])
        })
        }
       // alert(xy)
        setArrayEntry(arrayEntry => [...arrayEntry,<>
        <button className="numPlusButtonSelected">{item[0].plusOne}</button><button className='PriceButtonSelected'>€ {item[0].ticketPrice}.00</button><button className="editButtonSelected" onClick={editEntry} value={index}> </button>
        </>])
          mTotal += item[0].ticketPrice
          xy += 1
      })
     
      setTotal(mTotal)

     }

    // function myentryEdit(mindex) {
    //   alert(mindex)
    //   console.log("edit entries on ", mindex)
    //   setEditArrayEntry(editArrayEntry => [])
    //   setEditIndex(mindex)
    //   setIsEdit(true)
    // } 
    const editEntry = (e) => {
      //alert(e.target.value)
      console.log("edit entries on ", e)
      //clear entries array
      setEditArrayEntry(editArrayEntry => [])
      setEditIndex(e.target.value)
      setIsEdit(true)
     }

     const handleBoosted = () => {
      console.log("Boosted", isBoosted)
      if (isBoosted) {
        setIsBoosted(false)
      } else {
        setIsBoosted(true)
      }
     }

     //supported project
     const handleSelectedProject = (e) => {
      
      var x = 0
      for(x = 1; x <= projectArray.length; x++){
        if (e.target.id == x) {
         document.getElementById('project'+x).className = 'projectPlayHolderSelected'
         //document.getElementById('supportedProject').innerHTML = e.target.title
         setSupportedProject(e.target.id)
         setSupportedProjectName(e.target.title)
        } 
        else {
          document.getElementById('project'+x).className = 'projectPlayHolder'
        }
      }
    

        
     }

     const luckyclickMyNumber = (num) => {
      
      switch(parseInt(num)) {
        case 1:
          setIsSelected1(true)
          break   
        case 2:
          setIsSelected2(true)
          break 
        case 3:
          setIsSelected3(true)
          break 
        case 4:
          setIsSelected4(true)
          break
        case 5:
          setIsSelected5(true)
          break
        case 6:
            setIsSelected6(true)
            break
        case 7:
            setIsSelected7(true)
            break
        case 8:
            setIsSelected8(true)
            break
        case 9:
            setIsSelected9(true)
            break
        case 10:
            setIsSelected10(true)
            break
          case 11:
              setIsSelected11(true)
              break
          case 12:
              setIsSelected12(true)
              break
          case 13:
              setIsSelected13(true)
              break
          case 14:
              setIsSelected14(true)
              break
          case 15:
              setIsSelected15(true)
              break
          case 16:
              setIsSelected16(true)
              break
          case 17:
              setIsSelected17(true)
              break
          case 18:
              setIsSelected18(true)
              break
          case 19:
              setIsSelected19(true)
              break
          case 20:
              setIsSelected20(true)
              break
          case 21:
              setIsSelected21(true)
              break
          case 22:
              setIsSelected22(true)
              break
          case 23:
              setIsSelected23(true)
              break
          case 24:
              setIsSelected24(true)
              break
          case 25:
              setIsSelected25(true)
              break
          case 26:
              setIsSelected26(true)
              break
          case 27:
              setIsSelected27(true)
              break
          case 28:
              setIsSelected28(true)
              break
          case 29:
              setIsSelected29(true)
              break
          case 30:
              setIsSelected30(true)
              break
          case 31:
              setIsSelected31(true)
              break
          case 32:
              setIsSelected32(true)
              break
          case 33:
              setIsSelected33(true)
              break
          case 34:
              setIsSelected34(true)
              break
          case 35:
              setIsSelected35(true)
              break
          case 36:
              setIsSelected36(true)
              break
          case 37:
              setIsSelected37(true)
              break
          case 38:
              setIsSelected38(true)
              break
          case 39:
              setIsSelected39(true)
              break
          case 40:
              setIsSelected40(true)
              break
          case 41:
              setIsSelected41(true)
              break
          case 42:
              setIsSelected42(true)
              break
          case 43:
              setIsSelected43(true)
              break
          case 44:
              setIsSelected44(true)
              break
          case 45:
              setIsSelected45(true)
              break
        default:
          return 'foo';
      }
    }

    const luckyhandleClickPlusOne = (num) => {
      clearPlusOne()
      console.log("Plus one selected",num)
      console.log("Plus one selected class", num)
      console.log("Plus one is Edit", isEdit)
        setPlusOne(num);
        switch(parseInt(num)) {
          case 1:
            setIsPlusSelected1(true)
            break   
          case 2:
             setIsPlusSelected2(true)
            break 
          case 3:
            setIsPlusSelected3(true)
            break 
          case 4:
             setIsPlusSelected4(true)
            break
          case 5:
            setIsPlusSelected5(true)
            break
          case 6:
            setIsPlusSelected6(true)
              break
          case 7:
            setIsPlusSelected7(true)
              break
          case 8:
            setIsPlusSelected8(true)
              break
          case 9:
            setIsPlusSelected9(true)
              break
          default:
            setIsPlusSelected0(true)
            break
        }
      
  }

     //lucky pick
     const handleLuckyPick = () => {
      const min = 1;
      const max = 45;
      const outputArray = []
      setLuckyPick(true)
      handleClearSelection()
    
        for (var j = 0; outputArray.length < 6; j++) {
          const rand = min + Math.floor(Math.random() * (max - min));
          const isFound = outputArray.some(element => {
            if (element === rand) {
              return true;
            }
        
            return false;
          });
            
            if (!isFound) {
                outputArray.push(rand);
                setIdList(idList => [...idList, rand])
                setLuckyPick(true)
                //handleClearSelection()
                luckyclickMyNumber(rand)
                //document.getElementById("select"+rand).click()
                const rand1 = 0 + Math.floor(Math.random() * (10 - 0));
                luckyhandleClickPlusOne(rand1)
                //document.getElementById("plus"+rand1).click()
                console.log(rand1)
                console.log("isSelected"+rand)
                
            }
            
        }
        console.log(outputArray);
     }

     const handleProcessPayment = () => {
      if (entryList.length < 1) {
        setAlertImage("./images/red-x.png")
        setAlertTitle("FAILED")
        setAlertMessage("This is for testing purposes only andis not an official entry.")
        handleShowAlert()
      }

      const current = new Date();
      const mydate = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
        entryList.map((item, index) => {
      
          const data = {
            game_id: "123",
            user_id: user_id,
            supported_project_id: item[0].supported_project_id,
            number_selected: item[0].entries +','+ item[0].plusOne,
            status: "valid",
            isLuckyBoost: isBoosted,
            entry_type: isLuckypick ? "lucky_pick" : "user_selection",
            bet_fee: item[0].ticketPrice,
            draw_date: mydate,
            created_at: Date.now(),
          }
          
          try {
                    
            const url = Domain_Url.LuckyGiving+"/api/gameEntries";
            const { data: res } = axios.post(url, data);
            //alert("save")
            //navigate("/login");
            //window.location.reload();
            //console.log(data);
            setIsRefresh(true)
            console.log(res);
            setAlertImage("./images/red-check.png")
            setAlertTitle("SUCCESSFUL")
            setAlertMessage("This is for testing purposes only and is not an official entry.")
            handleShowAlert()
            
        } catch (error) {
          alert(error);
        }
         
       })
      
        
     }

     function format (date) {  
      if (!(date instanceof Date)) {
        throw new Error('Invalid "date" argument. You must pass a date instance')
      }
    
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
    
      return `${year}-${month}-${day}`
    }

     //useEffect here
     // handle entry displays
     useEffect(() => {
      console.log("My Entry", entryList)
      document.getElementById('entiresHolder').style.height=mHeight*entryList.length+"px";
      getEntry()
      
      handleClearSelection()
      setIdList(idList=>[])
     }, [entryList])

     //edit entries 
    useEffect(() => {
      setIdList(idList=>[])
      console.log("Edit pick", editIndex)
      console.log("Edit map", entryList)
      console.log("Edit idLists", idList)
      //alert(isEdit)
      if(isEdit) {
        handleClearSelection()
        entryList.map((item, index) => {
          //alert(item[0].supported_project_id)
          if(index === parseInt(editIndex))
          {
            //alert(item[0].supported_project_id)
            {item[0].entries.map((entry) => {
              //alert(entry)
              clickMyNumber(entry)
              //alert(entry)
             
              //document.getElementById("select"+entry).click()
              setEditArrayEntry(editArrayEntry => [...editArrayEntry,entry])
              //setIdList(idList => [...idList, entry])
            })
            }
              document.getElementById("plus"+item[0].plusOne).click()
              setIsBoosted(item[0].isBoosted)
              setSupportedProject(item[0].supported_project_id)
              setSupportedProjectName(item[0].supported_project_name)
              console.log("Edit project", supported_project_id)
              //isProjectSelected
              setIsProjectSelected(false)
              resetProjectSelected()
              document.getElementById('project'+item[0].supported_project_id).className = 'projectPlayHolderSelected'
              
          }
        })
      }
      setIsEdit(false)
   }, [isEdit])
   useEffect(() => {
    console.log('Updated State idList', idList)
    
   
 }, [idList])
 useEffect(() => {
  console.log('Updated State editArrayEntry', editArrayEntry)
  console.log('len State editArrayEntry', editArrayEntry.length)
  console.log("Edit map", entryList)
 
}, [editArrayEntry])

useEffect(() => {
  if(isEditSave)
  {
    console.log('Ticket number has been updated', entryList)
    getEntry()
    setIsEditSave(false)
    document.getElementById('editInputIndex').value = ""
    handleClearSelection()
  }
 
}, [isEditSave])

useEffect(() => {
  if (isBoosted) {
    setPrice(15)
  } else {
    setPrice(5)
  }
  console.log("Boosted price", price)
}, [isBoosted])



  return (
    <>
        <div className='play-bet-box'>
            <div className="play-row">
                <div className="play-column">
                    <h1 className='play-bet-title'>LET'S PLAY 6/45 + 1</h1>
                    <span className='play-bet-text'>SELECT A PROJECT YOU WANT TO SUPPORT</span>
                    <div className='projectPlay' id='projectLists'>{ getProjects()}</div>
                    <div className='select-num-box'>
                      <div className='select-num-title'>SELECT 6 NUMBERS + 1 NUMBER</div><div className='lucky-pick-holder '><img src={Domain_Url.Localhost+'/images/lucky_pick_logo.png'} width='14'/> <Link onClick={handleLuckyPick}>LUCKY PICK</Link></div>
                        <div className='plusBox' id='numSelection'>
                          <button className={isSelected1 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select1"} value={1}>{1}</button>
                          <button className={isSelected2 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select2"} value={2}>{2}</button>
                          <button className={isSelected3 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select3"} value={3}>{3}</button>
                          <button className={isSelected4 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select4"} value={4}>{4}</button>
                          <button className={isSelected5 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select5"} value={5}>{5}</button>
                          <button className={isSelected6 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select6"} value={6} >{6}</button>
                          <button className={isSelected7 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select7"} value={7} >{7}</button>
                          <button className={isSelected8 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select8"} value={8}>{8}</button>
                          <button className={isSelected9 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select9"} value={9}>{9}</button>
                          <button className={isSelected10 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select10"} value={10}>{10}</button>
                          <button className={isSelected11 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select11"} value={11}>{11}</button>
                          <button className={isSelected12 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select12"} value={12}>{12}</button>
                          <button className={isSelected13 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select13"} value={13}>{13}</button>
                          <button className={isSelected14 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select14"} value={14}>{14}</button>
                          <button className={isSelected15 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select15"} value={15}>{15}</button>
                          <button className={isSelected16 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select16"} value={16}>{16}</button>
                          <button className={isSelected17 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select17"} value={17}>{17}</button>
                          <button className={isSelected18 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select18"} value={18}>{18}</button>
                          <button className={isSelected19 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select19"} value={19}>{19}</button>
                          <button className={isSelected20 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select20"} value={20} >{20}</button>
                          <button className={isSelected21 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select21"} value={21} >{21}</button>
                          <button className={isSelected22 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select22"} value={22} >{22}</button>
                          <button className={isSelected23 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select23"} value={23} >{23}</button>
                          <button className={isSelected24 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select24"} value={24} >{24}</button>
                          <button className={isSelected25 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select25"} value={25} >{25}</button>
                          <button className={isSelected26 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select26"} value={26} >{26}</button>
                          <button className={isSelected27 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select27"} value={27} >{27}</button>
                          <button className={isSelected28 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select28"} value={28} >{28}</button>
                          <button className={isSelected29 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select29"} value={29} >{29}</button>
                          <button className={isSelected30 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select30"} value={30} >{30}</button>
                          <button className={isSelected31 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select31"} value={31} >{31}</button>
                          <button className={isSelected32 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select32"} value={32} >{32}</button>
                          <button className={isSelected33 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select33"} value={33} >{33}</button>
                          <button className={isSelected34 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select34"} value={34} >{34}</button>
                          <button className={isSelected35 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select35"} value={35} >{35}</button>
                          <button className={isSelected36 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select36"} value={36} >{36}</button>
                          <button className={isSelected37 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select37"} value={37} >{37}</button>
                          <button className={isSelected38 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select38"} value={38} >{38}</button>
                          <button className={isSelected39 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select39"} value={39} >{39}</button>
                          <button className={isSelected40 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select40"} value={40} >{40}</button>
                          <button className={isSelected41 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select41"} value={41} >{41}</button>
                          <button className={isSelected42 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select42"} value={42} >{42}</button>
                          <button className={isSelected43 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select43"} value={43} >{43}</button>
                          <button className={isSelected44 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select44"} value={44} >{44}</button>
                          <button className={isSelected45 ? "numButtonSelected" : "numButton"} onClick={handleClick} id={"select45"} value={45} >{45}</button>
                      
                        </div>
                        <div>
                        <button className={isPlusSelected0 ? "numPlusButtonSelected" : "numButton"} onClick={handleClickPlusOne} value={0} id={'plus'+0}>0{0}</button>
                        <button className={isPlusSelected1 ? "numPlusButtonSelected" : "numButton"} onClick={handleClickPlusOne} value={1} id={'plus'+1}>0{1}</button>
                        <button className={isPlusSelected2 ? "numPlusButtonSelected" : "numButton"} onClick={handleClickPlusOne} value={2} id={'plus'+2}>0{2}</button>
                        <button className={isPlusSelected3 ? "numPlusButtonSelected" : "numButton"} onClick={handleClickPlusOne} value={3} id={'plus'+3}>0{3}</button>
                        <button className={isPlusSelected4 ? "numPlusButtonSelected" : "numButton"} onClick={handleClickPlusOne} value={4} id={'plus'+4}>0{4}</button>
                        <button className={isPlusSelected5 ? "numPlusButtonSelected" : "numButton"} onClick={handleClickPlusOne} value={5} id={'plus'+5}>0{5}</button>
                        <button className={isPlusSelected6 ? "numPlusButtonSelected" : "numButton"} onClick={handleClickPlusOne} value={6} id={'plus'+6}>0{6}</button>
                        <button className={isPlusSelected7 ? "numPlusButtonSelected" : "numButton"} onClick={handleClickPlusOne} value={7} id={'plus'+7}>0{7}</button>
                        <button className={isPlusSelected8 ? "numPlusButtonSelected" : "numButton"} onClick={handleClickPlusOne} value={8} id={'plus'+8}>0{8}</button>
                        <button className={isPlusSelected9 ? "numPlusButtonSelected" : "numButton"} onClick={handleClickPlusOne} value={9} id={'plus'+9}>0{9}</button>
                        </div>
                        {/* <div>{getPlusOneButton(9)}</div> */}
                    </div>
                    <button className={isBoosted ? "lucky-pill-button" : "lucky-pill-button-not-selected"} onClick={handleBoosted}>LUCKY BOOST</button>
                    <button className='clear-pill-button' onClick={hanldeSaveSelection}>SAVE SELECTION</button>
                    <button className='clear-pill-button' onClick={handleClearSelectionTapped} id='clearBtn'>CLEAR</button>
                    
                </div>
                <div className="play-column">
                    <h2 className='h2Entry'>YOUR ENTRY</h2>
                    <div id='supportedProject'></div>
                    <div className='entriesHolder' id='entiresHolder'>{arrayEntry}</div>
                    <div className='totalHolder'>TOTAL PRICE <div className='totalPriceHolder'>€ {total}.00</div></div>
                    <div className='totalNote'>Note: 50% of your total will be donated to the project/s you supported.</div>
                    <div><button className='results-pill-button' onClick={handleProcessPayment}>PROCESS PAYMENT</button></div>
                    <input type='hidden' value={editIndex} id='editInputIndex'/>
                </div>
            </div>
        </div>
        <Alert isOpen={showAlert} onRequestClose={handleShowAlert}
            src= {alertImage}
            title={alertTitle}
            message={alertMessage}
            messagePart=""
            isRefresh={isRefresh}
        />
    </>
  )
}

export default PlayBetArea