import React from 'react'
import '../css/Results.css'
import {Domain_Url} from '../mocks/Constants';

function LuckyGivingCircle() {
  return (
    <>
    <img src={Domain_Url.Localhost+'/images/BALL.png'} width={200}/>
     {/* <div className='circle-title'>
        Lucky Giving
        <div className='text-border'>6/45</div>
    </div> */}
    </>
  )
}

export default LuckyGivingCircle