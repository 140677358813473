import React from 'react'
import '../css/Mainbanner.css'
import { Link } from "react-router-dom";

function Mainbanner() {
  return (
    <div className='banner-holder'>
        <img src='/images/banner.png' className='banner' alt='Banner'/>
        <div className='banner-play'>
          <Link to='/play'><button className='pill-button'>PLAY NOW</button></Link>
        </div>
    </div>
  )
}

export default Mainbanner