import React from 'react';
import '../../App.css';
import ComingSoon from '../screens/ComingSoon';

export default function Services() {
  return (
    <>
      <ComingSoon title='Test your luck soon. Exciting games are coming your way.'/>
    </>
  )
}
