import React from 'react'
import '../css/HowItWork.css';
import { Link } from 'react-router-dom';

function HowItWork() {
  return (
    <>
        <div className='howHolder'>
            <div className='how-title'>HOW IT WORKS</div>
            <div className="how-row">
                <div className="how-column-num">
                    1
                </div>
                <div className="how-column-text">
                    Select any project you want to support. Choose campaigns that are close to your heart.
                </div>
            </div>
            <div className="how-row">
                <div className="how-column-num">
                    2
                </div>
                <div className="how-column-text">
                    Pick your lucky numbers. You can do it the classic way, randomly via Lucky Pick or set your favorite numbers to get that jackpot.
                </div>
            </div>
            <div className="how-row">
                <div className="how-column-num">
                    3
                </div>
                <div className="how-column-text">
                    Confirm your entries and wait for the draw.
                </div>
            </div>
            <div className='how-title'><Link to="../play"><button className='results-pill-button'>PLAY NOW</button></Link></div>
        </div>
    </>
  )
}

export default HowItWork