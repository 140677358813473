import React, {useState, useEffect} from 'react'
import Modal from 'react-modal/lib/components/Modal'
import { Link } from 'react-router-dom';
import '../css/Login.css';
import '../css/Alert.css';

function AlertLogout(props) {
    const [modalIsOpen, setIsOpen] = useState({...props.isOpen});
    useEffect(() => {
        //alert(props.isOpen);
        setIsOpen(props.isOpen);
    }, [props.isOpen])
    
    const afterOpenModal=() => {
        // references are now sync'd and can be accessed.
       
      }
    
    const closeModal=() => {
        setIsOpen(false);
        window.location.reload();
       
      } 
    
    const removeToken = () => {
        localStorage.removeItem("token");
        window.location.href='/';
    }
  return (
    <>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            className='modalBody'
        >
            
            <div className='closeHolder'><Link onClick={closeModal}>X</Link></div>
                <div className='alert-title'></div>
                <div className='alert-title-logout'>{props.title}</div>
                <div className='alert-text-logout'>{props.message}</div>
                <div className='logoutButtonHolder'><button className='goback-pill' onClick={removeToken}>CONFIRM</button></div>
        </Modal>
    </>
  )
}

export default AlertLogout