import React from 'react'
import '../css/ProjectItemDetails.css';
import LuckyGivingCircle from './LuckyGivingCircle'
import {Domain_Url} from '../mocks/Constants';
import { Link } from "react-router-dom";

function ProjectItemDetails(props) {
  return (
    <>
        <img
            className='project-img'
              alt='Travel'
              src={Domain_Url.Localhost+props.banner}
        />
        <div className='project-fund'>
            <div className='fundTitle'>FUNDS RAISED</div>
            <div className='fundValue'>€ 245,127.00</div>
        </div>
        <div className='project-item-info'>
            <div className='project-item-info-80'>
                <h1 className='cards__item__title'>{props.label}</h1>
                <h2 className='cards__item__subtitle'>{props.subtitle}</h2>
                <h5 className='cards__item__text'>{props.text}</h5>
                <p className='cards__item__text'>{props.body}</p>
            </div>
            <div className='project-item-info-20'>
            <table>
                <tr>
                  <td align='center'>
                    <LuckyGivingCircle />
                  </td>
                </tr>
                <tr>
                  <td>
                  <p><Link to='/play'><button className='results-pill-button'>SUPPORT NOW</button></Link></p>
                  </td>
                </tr>
              </table>
            </div>
        </div>
    </>
  )
}

export default ProjectItemDetails