import React from 'react'
import ComingSoon from '../screens/ComingSoon'

function History() {
  return (
    <>
     <ComingSoon title='Catch the history soon.'/>
    </>
  )
}

export default History