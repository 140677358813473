import React,{useState, useEffect} from 'react'
import { useParams, Link } from "react-router-dom"
import Project from '../screens/Project'
import Countdown from '../screens/Countdown'
import Footer from '../screens/Footer'
import '../css/Project.css';
import {Domain_Url} from '../mocks/Constants';
import axios from "axios";

function SupportedProjects() {
    const fname = localStorage.getItem("fname");
    const [isTotal, setTotal] = useState(false)
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("userId");
    const [responseData, setResponseData] = useState([])
    const projectArray = []
    const [projectId, setProjectId] = useState([])
    //const [myDonation, setDonation] = useState()
  var myDonation = 0
    
    const getSupportedProjects = () => {
     
      const data = {
        user_id: user_id,
      }
          
          try {
                    
             const url = Domain_Url.LuckyGiving+"/api/findSupported";
            axios({
 
              // Endpoint to send files
              url: url,
              method: "POST",
              headers: {
   
                  // Add any auth token here
                  authorization: token,
              },
   
              // Attaching the form data
              data: data,
          })
   
              // Handle the response from backend here
              .then((res) => {
                //alert(res.data.projects[0].totalAmount)
                console.log("Data", res.data)
                setResponseData(res.data)
                //setProjectId(myproject)
                // setProjectId([<button className="numButtonSelected">{res.data.projects[0].totalAmount}</button>])
                })
                //return projectArray
              
              // Catch errors if any
              .catch((err) => {
                //alert("Error")
               });
            
            
        } catch (error) {
          alert(error);
        }
       

     }
     //if(!isTotal) {
     // getSupportedProjects()
      //setTotal(true)
     //}
  
     useEffect(() => {
      const url = Domain_Url.LuckyGiving+"/api/findSupported";
      const data = {
        user_id: user_id,
      }
            axios({
 
              // Endpoint to send files
              url: url,
              method: "POST",
              headers: {
   
                  // Add any auth token here
                  authorization: token,
              },
   
              // Attaching the form data
              data: data,
          })
   
              // Handle the response from backend here
              .then((res) => {
                //alert(res.data.projects[0].totalAmount)
                // var tD = 0
                res.data.projects.map((item,index) => {
                  console.log("Projects",item)
                 
                  //setResponseData(item)
                  if(index === res.data.projects.length - 1) {
                    setResponseData(res.data.projects)
                  }
                })
                //setDonation((tD/2).toFixed(2))
                //setResponseData(res.data.projects)
                //setProjectId(myproject)
                // setProjectId([<button className="numButtonSelected">{res.data.projects[0].totalAmount}</button>])
                })
                //return projectArray
              
              // Catch errors if any
              .catch((err) => {
                //alert("Error")
               });
    }, [])

  return (
    <>
    <div className='hello-projects'>
      <div className='hello-box'>
        <div className='hello-project-row'>
          <div className='hello-project-column'>
            <div className='hello-name'>Hello {fname},</div>
            <div className='member-since'>Member since 2022</div>
          </div>
          <div className='project-supported-box'>
            <h1 className='project-supported-header'>PROJECTS YOU'VE SUPPORTED</h1>
            <div className='hello-project-row'>
              <div className='hello-project-column-supported'>
                <h2 className='project-supported-headtitle'>PROJECT</h2>
                {responseData.map((items, index) => {
                return <Link to={items.projectId}><div className={index % 2 === 0 ? "supported-bg" : "supported-bg-none"}>{items.name}</div></Link>
              })} 
              </div>
              <div className='hello-project-column-supported'>
                <h2 className='project-supported-headtitle'>FUND RAISED</h2>
                {/* {getSupportedProjects()} */}
                
                {
                 isTotal ? <div className='project-zero'>You have not supported any project yet.</div> : responseData.map((items, index) => {
                  return <Link to={items.projectId}><div className={index % 2 === 0 ? "supported-bg" : "supported-bg-none"}>	&euro; {items.totalFund.toFixed(2)}</div></Link>
                })}
                
              </div>
              <div className='hello-project-column-supported'><h2 className='project-supported-headtitle'>YOUR DONATION</h2>
              {
              
              responseData.map((items,index) => {
				        var donation = parseInt(items.totalAmount)/2
                
                 return <Link to={items.projectId}><div className={index % 2 === 0 ? "supported-bg-money" : "supported-bg-none-money"}>&euro; {donation.toFixed(2)}</div></Link>
                
              })

              } 

              </div>
            </div>
          </div>
          <div className='hello-project-column'>
            <div className='total-amount-donated'>TOTAL AMOUNT DONATED</div>
            <div className='total-amount-donated-price'>€ {
              
               responseData.map((items,index) => {
                 var donation = parseInt(items.totalAmount)/2
                 myDonation+= donation
               })
               
            } {myDonation.toFixed(2)}</div>
          </div>
          
        </div>
      </div>
    </div>
    <div className='other-projects'>OTHER PROJECTS YOU CAN SUPPORT</div>
    <Project/>
    <Countdown/>
    <Footer/>
    </>
  )
}

export default SupportedProjects