import React from 'react'
import '../../App.css';
import ComingSoon from '../screens/ComingSoon';

function Draw() {
  return (
    <>
     <ComingSoon title='Catch the live draw soon.'/>
    </>
  )
}

export default Draw