import React from 'react'
import '../css/Footer.css';
// import { Button } from './Button';
import { Link } from 'react-router-dom';
import {Domain_Url} from '../mocks/Constants';

function Footer() {
  const today = new Date();
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  return (
    <>
    <div className='footer-container'>
      {/* start div holder */}
      <div className='footer-holder'>
        <div className='footer-holder-row'>
          <div className='footer-holder-cell-left'>
            <div className='footer-table'>
              <div className='footer-table-row'>
                <div className='footer-table-cell'>
                  INFORMATION
                  <div class='footer-link-items'>
                    <Link to='/faq' onClick={topFunction}>FAQ</Link>
                    <Link to='/mechanics' onClick={topFunction}>Mechanics</Link>
                    <Link to='/licenses' onClick={topFunction}>Licenses</Link>
                    <Link to='/privacypolicy' onClick={topFunction}>Privacy Policy</Link>
                    <Link to='/termsconditions' onClick={topFunction}>Terms of Conditions</Link>
                  </div>
                </div>
                <div className='footer-table-cell'>
                  SUPPORT
                  <div class='footer-link-items'>
                    <Link to='/contactus'>Contact us</Link>
                      <h2>FOLLOW US</h2>
                      <table>
                        <tr>
                          <td>
                            <Link to='/'><img src={Domain_Url.Developmenthost+'/images/twitter.png'} alt='twitter'/></Link>
                          </td>
                          <td>
                            <Link to='/'><img src={Domain_Url.Developmenthost+'/images/youtube.png'} alt='twitter'/></Link>
                          </td>
                        </tr>
                      </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-holder-cell'>
            {/*start part 2*/}
            <div className='footer-table'>
              <div className='footer-table-row'>
                <div className='footer-table-cell-40'>
                  OUR PARTNERS
                  <div class='footer-link-items'>
                    <div className='partners-table'>
                      <div className='partners-table-row'>
                        <div className='partners-table-cell'><a href="https://www.jeton.com/jetoncash" target="_blank" rel="noopener noreferrer">Payment</a></div>
                        <div className='partners-table-cell'><a href='https://www.randomizer.org/' target="_blank" rel="noopener noreferrer">Randomizer</a></div>
                        <div className='partners-table-cell'><a href='https://www.emirat.de/en' target="_blank" rel="noopener noreferrer">Risk Management</a></div>
                      </div>
                      <div className='partners-table-row'>
                        <div className='partners-table-cell'><a href="https://www.jeton.com/jetoncash" target="_blank" rel="noopener noreferrer"><img src={Domain_Url.Developmenthost+'/images/jeton.png'} alt='Jeton' className='img75'/></a></div>
                        <div className='partners-table-cell'><a href='https://www.randomizer.org/' target="_blank" rel="noopener noreferrer"><img src={Domain_Url.Developmenthost+'/images/randomizer_logo.png'} alt='Randomizer' className='img75'/></a></div>
                        <div className='partners-table-cell'><a href='https://www.emirat.de/en' target="_blank" rel="noopener noreferrer"><img src={Domain_Url.Developmenthost+'/images/emirat_logo.png'} alt='Randomizer' className='img75'/></a></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='footer-table-cell'>
                  PLAY RESPONSIBLY
                  <div class='footer-link-items'>
                    <Link to='/'>Players must be 18 or over</Link>
                    <div className='box18'>18+</div>
                  </div>
                </div>
              </div>
            </div>
            {/*end part 2*/}
          </div>
        </div>
      </div>
      {/* end div holder */}

    </div>
    <div className='website-rights'>© {today.getFullYear()} Lucky Giving. All rights reserved.</div>
    </>
  )
}

export default Footer