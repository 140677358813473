import React from 'react'
import '../css/Faq.css';

function FaqData(props) {
  return (
    <>
        <div className='faq-holder'>
            <div className='faq-title'>{props.title}</div>
            <div className='faq-text'>{props.body}</div>
        </div>
    </>
  )
}

export default FaqData