import React, {useState, useEffect} from 'react'
import Modal from 'react-modal/lib/components/Modal'
import { Link } from 'react-router-dom';
import '../css/Login.css';
import '../css/Alert.css';
import LoadingSpinner from "../screens/LoadingSpinner";

function AlertLoading(props) {
    const [modalIsOpen, setIsOpen] = useState({...props.isOpen});
    useEffect(() => {
        //alert(props.isOpen);
        setIsOpen(props.isOpen);
    }, [props.isOpen])
    
    const afterOpenModal=() => {
        // references are now sync'd and can be accessed.
       
      }
    
    const closeModal=() => {
        setIsOpen(false);
        if (!props.isRefresh) {

        } else {
        window.location.reload();
        }
      }

      const [loading, setLoading] = useState(true);
  return (
    <>
         <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            className='modalBodyLoading'
        >
               
                <div>
                {loading ? (
                    <LoadingSpinner  />
                ) : (
                    <div>Your content when loading is complete.</div>
                )}
            
                </div>
                
        </Modal>
    </>
  )
}

export default AlertLoading