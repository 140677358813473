import React from 'react'
import '../css/Privacy.css'
import Footer from './Footer'
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm';
// import LicensesBody from '../screens/LicensesBody'
function Privacybanner(props) {
  return (
    <>
        <div className='privacyBannerHolder'>
            <div className='BannerTitle'>{props.title}</div>
            <div className='leadText'>{props.lead}</div>
        </div>
        <p className='bodyText'>{props.bodyText}</p>
        <ContactForm />
        <Footer/>
    </>
  )
}

export default Privacybanner