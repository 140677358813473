import React from 'react'
import '../css/ComingSoon.css'

function ComingSoon(props) {
  return (
    <>
        <div className='coming-soon-title'>COMING SOON
         <div className='coming-soon-h2'>{props.title}</div>
        </div>
    </>
  )
}

export default ComingSoon