import React from 'react'
import '../../App.css';
import ComingSoon from '../screens/ComingSoon';

function Profile() {
  return (
    <>
     <ComingSoon title='Catch the my profile soon.'/>
    </>
  )
}

export default Profile