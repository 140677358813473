import React from 'react'
import '../css/NotFound.css'
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <>
        <div className='notFoundTitle'>404</div>
        <div className='notFoundText'>Ooops! Something went wrong.</div>
        <div className='pillHolder'><Link to='/'><button className='goback-pill'>GO BACK TO HOME</button></Link></div>
    </>
  )
}

export default NotFound