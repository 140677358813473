import React, {useState, useEffect} from 'react'
import Modal from 'react-modal/lib/components/Modal'
import { Link } from 'react-router-dom';
import '../css/Login.css';
import validator from 'validator';
import axios from "axios";
import {Domain_Url} from '../mocks/Constants';

function ForgotPassword(props) {
    const [modalIsOpen, setIsOpen] = useState({...props.isOpen});
    const [passwordClass, setPassword] = useState(true);
    const [emailClass, setEmail] = useState(true);
    const [error, setError] = useState("*must include 8 characters, 1 uppercase, 1 lowercase and 1 special character");
    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen])

    const afterOpenModal=() => {
        // references are now sync'd and can be accessed.
    
      }
    
    const closeModal=() => {
        setIsOpen(false);
      }
    
      const [data, setData] = useState({
        email: "",
        password: "",
    });

    const handleLoginChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validator.isEmail(data.email)) {
            setEmail(false);
        }
        else if (data.password === "") {
            setPassword(false);
        }
        else {
            //send data to server
            try {
                    
                const url = Domain_Url.LuckyGiving+"/api/auth";
                const { data: res } = await axios.post(url, data);
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("fname", res.data.fname);
			    window.location = "/";
                
            } catch (error) {
                if (
                    error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) {
                    setPassword(false);
                    setError(error.response.data.message);
                }
            }
        }
      }
  return (
    <>
        <Modal
            isForgot={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            className='modalBody'
        >
            
            <div className='closeHolder'><Link onClick={closeModal}>X</Link></div>
            <form onSubmit={handleSubmit}>
                <div className='loginHolder'>FORGOT PASSWORD</div>
                <div className='loginHolder'>
                    <input type='text' placeholder='Email' 
                        className={emailClass ? "inputLogin" : "inputLogin-red"}
                        onChange={handleLoginChange}
                        value={data.email}
                        name='email'
                    />
                </div>
                <div className='login-100-error'><span className={emailClass ? "hideDiv" : "showDiv"}>*Please provide valid email address</span></div>
                <div className='loginHolder'>
                    <input type='password' placeholder='Password' 
                        className={passwordClass ? "inputLogin" : "inputLogin-red"}
                        onChange={handleLoginChange}
                        value={data.password}
                        name='password'
                    />
                </div>
                <div className='login-100-error'><span className={passwordClass ? "hideDiv" : "showDiv"}>{error}</span></div>
                <div className='loginHolder'>
                    <button type='submit' className='btn-login'>Log In</button>
                </div>
                <div className='forgotHolder'>
                 <Link onClick={closeModal}>Forgot Password?</Link>
                </div>
            </form>
        </Modal>
    </>
  )
}

export default ForgotPassword