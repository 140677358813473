import React from 'react'
import '../css/Results.css'
import LuckyGivingCircle from './LuckyGivingCircle'
import { Link } from "react-router-dom";

function Results() {
  return (
    <>
      <div className='resultsHolder'>
        <div className='results-div-table'>
          <div className='results-div-table-row'>
            <div className='results-div-table-col-60'>
              <h1 className='results-title'>LATEST RESULTS</h1>
              <div className='results-date'>MONDAY, JULY 3, 2023</div>
              <div className='ball-table'>
                <div className='ball-table-row'>
                  <div className='ball-table-cell'>
                    <div className='circle-ball'>7</div>
                  </div>
                  <div className='ball-table-cell'>
                    <div className='circle-ball'>27</div>
                  </div>
                  <div className='ball-table-cell'>
                    <div className='circle-ball'>28</div>
                  </div>
                  <div className='ball-table-cell'>
                    <div className='circle-ball'>24</div>
                  </div>
                  <div className='ball-table-cell'>
                    <div className='circle-ball'>36</div>
                  </div>
                  <div className='ball-table-cell'>
                    <div className='circle-ball'>45</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='results-div-table-col-40'>
              <table>
                <tr>
                  <td align='center'>
                    <LuckyGivingCircle />
                  </td>
                </tr>
                <tr>
                  <td>
                  <p><Link to='/play'><button className='results-pill-button'>PLAY NOW</button></Link></p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Results