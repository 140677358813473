import React, {useState} from 'react'
import JsonData from '../mocks/faq.json';
import FaqData from '../screens/FaqData'
import ContactForm from '../screens/ContactForm';
import Footer from '../screens/Footer';
import { Link } from 'react-router-dom';
import '../css/Faq.css';

function Faq() {
  const [searchTitle, setTitle] = useState("FREQUENTLY ASKED QUESTIONS");
  const [searchdata, setData] = useState({
    search: "",
  });
  const handleChange = ({ currentTarget: input }) => {
    setData({ ...searchdata, [input.name]: input.value });
  };

  const [searchText, setText] = useState("");

  const handleSearch = () => {
    setText(searchdata.search);
    setTitle('SEARCH RESULTS FOR "'+searchdata.search+'"');
  }
  

  return (
    <>
    <div className='privacyBannerHolder'>
            <div className='BannerTitle' id='how'>How can we help you?</div>
            <div className='leadText'><input type='text' name='search' placeholder='Ask a question?' className='faq-input' value={searchdata.search} onChange={handleChange}/></div>
            <div className='pillHolder'><button className='goback-pill' onClick={handleSearch}>SEARCH</button></div>
    </div>
    <div className='banner-title'>{searchTitle}</div>
    {
        JsonData
    .filter((data) => {
      return data.title.toLowerCase().includes(searchText.toLowerCase())
    })
    .map((item) => (
        <FaqData
        title={item.title}
        body={item.body}
      />
    ))}
    <ContactForm/>
    <Footer/>
    </>
  )
}

export default Faq