
import React, {useState, useEffect} from 'react'
import '../css/ComingSoon.css';
import axios from "axios";
import Alert from '../pages/Alert';
import {Domain_Url} from '../mocks/Constants';

function HomeComingSoon() {
    const [count, setCount] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    
    useEffect(() => {
        setCount((count) => count + 1);
        if (count > 1) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
      }, [showAlert])

    const handleShowAlert = () => {
        if (showAlert) {
            setShowAlert(false);
        }
        else {
            setShowAlert(true);
        }
      }
    const [emailClass, setEmail] = useState(true);
    const [data, setData] = useState({
        email: "",
    });

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
        if(data.email != "") {
            setEmail(true);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validateEmail(data.email)){
            setEmail(false);
          }else {
            try {
                
                const url = Domain_Url.LuckyGiving+"/api/mailinglists";
                const { data: res } = await axios.post(url, data);
                handleShowAlert();
                console.log(data);
                console.log(res.message);    
                cleanData();        
            } catch (error) {
                if (
                    error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) {
                    alert(error.response.data.message);
                }
            }
        }
    }
    const cleanData = ()=> {
        document.getElementById("email").value = ""; 
    }
    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };
  return (
    <>
        <div className='rowHome'>
                <div className='columnHome-a'>
                    {/* <div className='comingTitle'><span className='comingTitleTopLeft'>Introducing</span><br/>Luckygiving</div> */}
                    <img src='images/LuckyGiving_Logo-Full-White.png' className='logoHead'/>
                </div>
                <div className='columnHome-b'>
                    <div className='home-soon-text'>
                    <img src='images/luckyHand.png' alt='hands' className='comingHeadImage'/><br/>
                    The more you give, the more you get.<br/>We bring more excitement to fundraising!
                    </div>
                </div>
                <div className='columnHome-d'>
                    <div className='home-soon-text'>
                    <img src='images/luckyHeart.png' alt='hands' className='comingHeadImage'/><br/>
                    Every project we highlight will receive fifty percent<br/> (50%) of all contributions made by Luckygivers<br/> who join our draws and games.
                    </div>
                </div>
                <div className='columnHome-c'>
                    <div className='home-soon-text-petal'>
                    <img src='images/luckyPetals.png' alt='hands' className='comingHeadImage'/><br/>
                    Simply participate in our lottery draws and fun instant<br/> games not only for a chance to win cash prizes but to<br/> contribute to worthy charities as well!
                    </div>
                </div>
        </div>
        <div className='beMail'>
            <div className='comingJoin'>Be the first to know when we launch. Join our mailing list.</div>
            <div className='comingJoinBox'>
                <input type='text' placeholder='Email' name="email" id="email" className={emailClass ? "inputHomeGreen" : "inputHomeRed"} onChange={handleChange}
							value={data.email}/> <button className='join-pill-button' onClick={handleSubmit}>JOIN</button>
                <span className={emailClass ? "hideDiv" : "showDiv"}>*Please enter valid email address</span>
            </div>
        </div>
        <Alert isOpen={showAlert} onRequestClose={handleShowAlert}
            src='./images/message.png'
            title='SUCCESS'
            message="You have been added to the mailing list."
            messagePart="Let's play soon!" 
        />
    </>
  )
}

export default HomeComingSoon