import React, { useEffect, useState, useRef } from "react";
import '../css/Join.css';
import { Button } from '../screens/Button';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import {Domain_Url} from '../mocks/Constants';
import LoadingSpinner from "../screens/LoadingSpinner";
import AlertLoading from "./AlertLoading";
import validator from 'validator';

function Join() {
    const [phonevalue, setValue] = useState("");
    const ref = useRef();
    const whitelisted_conutries = ['WS',  'SB',  'TK',  'TO',  'TV',  'VU',  'WF',  'HK', 'PH'];

        const [data, setData] = useState({
            firstName: "",
            lastName: "",
            phoneNumber: phonevalue,
            email: "",
            userName: "",
            birthDay: "",
            password: "",
            confirmPassword: "",
        });
        const [error, setError] = useState("");
	    const [msg, setMsg] = useState("");
        const navigate = useNavigate();
        const [pwd, showPwd] = useState(false);
        const [cpwd, cshowPwd] = useState(false);
        const [fnameClass, setFname] = useState(true);
        const [lnameClass, setLname] = useState(true);
        const [emailClass, setEmail] = useState(true);
        const [bdayClass, setBday] = useState(true);
        const [phoneClass, setPhone] = useState(true);
        const [usernameClass, setUsername] = useState(true);
        const [passwordClass, setPassword] = useState(true);
        const [confirmClass, setConfirm] = useState(true);
        const [agree, setAgree] = useState(false);
        const [isAgree, setisAgree] = useState(false);


        const handleChange = ({ currentTarget: input }) => {
            setData({ ...data, [input.name]: input.value });
        };

        const handleClick = () => {
            if (pwd) {
                showPwd(false);
            } else {
                showPwd(true);
            }
        }

        const handleClickConfirm = () => {
            if (cpwd) {
                cshowPwd(false);
            } else {
                cshowPwd(true);
            }
        }
    
        const handleSubmit = async (e) => {
            data.phoneNumber = phonevalue;
            e.preventDefault();
            resetInput()
            
            const currentYear = new Date().getFullYear();
            const year = data.birthDay.split("-")[0];
            const age = currentYear - year;

             if (data.lastName == "") {
                setLname(false);
            }
            else if (data.firstName == "") {
                setFname(false);
            }
            else if (data.birthDay == "" || age <18) {
                setBday(false);
            }
            else if (data.phoneNumber == "") {
                setPhone(false);
            }
            else if (!validator.isEmail(data.email)) {
                setEmail(false);
            }
            else if (data.userName == "") {
                setUsername(false);
            }
            else if (data.password == "") {
                setPassword(false);
            }
            else if (data.confirmPassword != data.password) {
                setConfirm(false);
            }
            else if (!agree) {
                //alert("Please agree");
                setisAgree(true);
                setPassword(false);
            }
            else {
                setLoading(true)
                try {
                    
                    const url = Domain_Url.DevServer+"/api/users";
                    const { data: res } = await axios.post(url, data);
                    setLoading(false)
                    //alert(res.message);
                    navigate(`/verify/${res.user_id}`);
                    //window.location.reload();
                    // console.log(res.message);
                    // console.log(res.user_id);
                    
                } catch (error) {
                    setLoading(false)
                    if (
                        error.response &&
                        error.response.status >= 400 &&
                        error.response.status <= 500
                    ) {
                       // alert(error.response.data.message);
                        setError(error.response.data.message);
                        if (error.response.data.message === "User with given email already Exist!") {
                            setEmail(false);
                        }
                        if (error.response.data.message === '"password" should contain at least 1 symbol') {
                            setPassword(false);
                        }
                    }
                }
            }
        }

        const handleCheckForm = () => {
            if (data.lastName == "") {
                setLname(false);
            }
            if (data.firstName == "") {
                setFname(false);
            }
            if (data.phoneNumber == "") {
                setPhone(false);
            }
            if (data.birthDay == "") {
                setBday(false);
            }
            if (data.email == "") {
                setEmail(false);
            }
            if (data.userName == "") {
                setUsername(false);
            }
            if (data.password == "") {
                setPassword(false);
            }
            if (data.confirmPassword != data.password) {
                setConfirm(false);
            }
        }

        const handleCheckBox = (e) => {
            if (agree) {
                setAgree(false);
                setPassword(false);
                setisAgree(true)
            } else {
                setAgree(true);
            }
        }

        const [loading, setLoading] = useState(false);
        const handleShowAlert = () => {
            if (loading) {
                setLoading(false);
            }
            else {
                setLoading(true);
            }
          }

        const resetInput = () => {
            setLname(true);
            setFname(true);
            setPhone(true);
            setBday(true);
            setEmail(true);
            setUsername(true);
            setPassword(true);
            setConfirm(true);
        }

        // useEffect(() => {
        //     // Simulating an asynchronous operation
        //     setTimeout(() => {
        //     setLoading(false);
        //     }, 3000);
        // }, []);
        
  return (
    <>
        <div className='joinHolder'>
        <div className='row'>
        <form onSubmit={handleSubmit} id='formData'>
            <div className='column-100-title'>CREATE AN ACCOUNT</div>
            <div className='column-50'>
                <input type='text' placeholder='Last Name' name="lastName"
                            className={lnameClass ? "inputGreen" : "inputRed"}
							onChange={handleChange}
							value={data.lastName}
							required/>
            </div>
            <div className='column-50'>
                <input type='text' placeholder='First Name' name="firstName"
                            className={fnameClass ? "inputGreen" : "inputRed"}
							onChange={handleChange}
							value={data.firstName}
							required/>
            </div>
            <div className='column-50-error'><span className={lnameClass ? "hideDiv" : "showDiv"}>*Please provide your last name</span></div>
            <div className='column-50-error'><span className={fnameClass ? "hideDiv" : "showDiv"}>*Please provide your first name</span></div>
            <div className='column-50-red'>
                <input type='text' placeholder='Birthdate' ref={ref}
                className={bdayClass ? "inputGreen" : "inputRed"}
                onChange={handleChange}
                value={data.birthDay}
                name='birthDay'
                //onChange={(e) => console.log(e.target.value)}
                onFocus={() => (ref.current.type = "date")}
                onBlur={() => (ref.current.type = "text")}/>
            </div>
            <div className='column-50'>
                <PhoneInput
                placeholder="Phone number"
                name='phoneNumber'
                countries={whitelisted_conutries}
                international
                countryCallingCodeEditable={false}
                initialValueFormat="national"
                defaultCountry="PH"
                value={phonevalue}
                className={phoneClass ? "inputTelGreen" : "inputTelRed"}
                //onChange={handleChange}
                //value={data.phoneNumber}
                onChange={setValue}
                />
            </div>
            <div className='column-50-error'><span className={bdayClass ? "hideDiv" : "showDiv"}>*age must be 18 and above</span></div>
            <div className='column-50-error'><span className={phoneClass ? "hideDiv" : "showDiv"}>*invalid format</span></div>
            <div className='column-50'>
                <input type='text' placeholder='Email' name="email"
                            className={emailClass ? "inputGreen" : "inputRed"}
							onChange={handleChange}
							value={data.email}
							required/>
            </div>
            <div className='column-50'>
                <input type='text' placeholder='Confirm Email'
                name='userName'
                className={usernameClass ? "inputGreen" : "inputRed"}
				            onChange={handleChange}
							value={data.userName}
							required
                />
            </div>
            <div className='column-50-error'><span className={emailClass ? "hideDiv" : "showDiv"}> {error ? error :"*Please provide valid email address"}</span></div>
            <div className='column-50-error'><span className={usernameClass ? "hideDiv" : "showDiv"}>*alphanumeric, max 20 characters, min 4 characters</span></div>
            <div className='column-50-relative'>
                <div className={passwordClass ? "input-icons" : "input-iconsRed"}>
                    {pwd ? <img src='./images/green_eye.png' onClick={handleClick} className='eyeClick'/> : <img src='./images/viewpassword_green.png' onClick={handleClick} className='eyeClick'/>}
                    <input placeholder='Password' type={pwd ? 'text' : 'password'} name="password"
                            className={passwordClass ? "inputGreen" : "inputRed"}
							onChange={handleChange}
							value={data.password}
							required/>
                </div>
            </div>
            <div className='column-50-relative'>
                <div className={confirmClass ? "input-icons" : "input-iconsRed"}>
                {cpwd ? <img src='./images/green_eye.png' onClick={handleClickConfirm} className='eyeClick'/> : <img src='./images/viewpassword_green.png' onClick={handleClickConfirm} className='eyeClick'/>}
                    <input placeholder='Confirm Password' type={cpwd ? 'text' : 'password'} name="confirmPassword"
                        className={confirmClass ? "inputGreen" : "inputRed"}
                        onChange={handleChange}
						value={data.confirmPassword}
                    />
                </div>
            </div>
            <div className='column-50-error'><span className={passwordClass ? "hideDiv" : "showDiv"}>{isAgree ? "Please agree to the Terms & Conditions" :"*must include 8 characters, 1 uppercase, 1 lowercase and 1 special character"}</span></div>
            <div className='column-50-error'><span className={confirmClass ? "hideDiv" : "showDiv"}>*Password does not match</span></div>
            <div className="agreeBox">
                <div>
                    <label className="container">By creating an account, I agree to the <Link to='/termsconditions'>Terms & Conditions</Link> and acknowledge that I have read the <Link to='/privacypolicy'>Privacy Policy</Link>
                    <input 
                        type="checkbox" 
                        name="agree"
                        value={agree}
                        onChange={handleCheckBox}
                    />
                    <span className="checkmark"></span>
                    </label>
                </div>
                <div className='column-100-button'>
                    <button type='submit' className='btn-submit' onClick={handleSubmit}>SIGN UP</button>
                </div>
            </div>
        </form>
        
        </div>
        {/* {error && <div>{error}</div>}
		{msg && <div>{msg}</div>} */}
           
        </div>
        <AlertLoading isOpen={loading} onRequestClose={handleShowAlert}/>
        
    </>
  )
}

export default Join