import React, {useState, useEffect} from 'react'
import Modal from 'react-modal/lib/components/Modal'
import { Link } from 'react-router-dom';
import '../css/Login.css';
import '../css/Alert.css';

function Alert(props) {
    const [modalIsOpen, setIsOpen] = useState({...props.isOpen});
    useEffect(() => {
        //alert(props.isOpen);
        setIsOpen(props.isOpen);
    }, [props.isOpen])
    
    const afterOpenModal=() => {
        // references are now sync'd and can be accessed.
       
      }
    
    const closeModal=() => {
        setIsOpen(false);
        if (!props.isRefresh) {

        } else {
        window.location.reload();
        }
      }
  return (
    <>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            className='modalBody'
        >
            
            <div className='closeHolder'><Link onClick={closeModal}>X</Link></div>
                <div className='alert-title'>
                  <img
                  className='alert-img'
                  alt='Alert'
                  src={props.src}/>
                </div>
                <div className='alert-title'>{props.title}</div>
                <div className='alert-text'>{props.message}</div>
                <div className='alert-text-part'>{props.messagePart}</div>
        </Modal>
    </>
  )
}

export default Alert