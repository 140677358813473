import React from 'react'
import { useParams } from "react-router-dom";
import isTaxID from 'validator/lib/isTaxID';
import JsonData from '../mocks/projects.json';
import ProjectItemDetails from '../screens/ProjectItemDetails';
import Thankyou from '../screens/Thankyou'
import Footer from '../screens/Footer'

function ProjectDetailView() {
  const { id, label } = useParams();
  return (
    <>
        {
        JsonData
    .filter((data) => {
      return data.id.includes(id)
    })
    .map((item) => (
        <ProjectItemDetails
        src={item.src}
        text={item.text}
        label={item.label}
        subtitle={item.subtitle}
        body={item.body}
        banner={item.banner}
      />
    ))}
    <Thankyou text="THANK YOU FOR YOUR SUPPORT"/>
    <Footer/>
    </>
  )
}

export default ProjectDetailView