import React from 'react'
import '../css/Licenses.css'


function LicensesBody() {
  return (
    <>
        <div className='licenseTable'>
            <div className='licenseTablerow'>
                <div className='licenseTablecella'>
                 <img src='./images/licenses_placeholder.png' alt='placeholder' className='licenseeImg'/>
                </div>
                <div className='licenseTablecellb'>
                    LOREM IPSUM
                    <p className='licenseTablecell-subtext'>Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed do
eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                </div>
            </div>
            <div className='licenseTablerow'>
                <div className='licenseTablecella'>
                 <img src='./images/licenses_placeholder.png' alt='placeholder' className='licenseeImg'/>
                </div>
                <div className='licenseTablecellb'>
                    LOREM IPSUM
                    <p className='licenseTablecell-subtext'>Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed do
eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                </div>
            </div>
            <div className='licenseTablerow'>
                <div className='licenseTablecella'>
                 <img src='./images/licenses_placeholder.png' alt='placeholder' className='licenseeImg'/>
                </div>
                <div className='licenseTablecellb'>
                    LOREM IPSUM
                    <p className='licenseTablecell-subtext'>Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed do
eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                </div>
            </div>
            <div className='licenseTablerow'>
                <div className='licenseTablecella'>
                 <img src='./images/licenses_placeholder.png' alt='placeholder' className='licenseeImg'/>
                </div>
                <div className='licenseTablecellb'>
                    LOREM IPSUM
                    <p className='licenseTablecell-subtext'>Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed do
eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                </div>
            </div>
            <div className='licenseTablerow'>
                <div className='licenseTablecella'>
                 <img src='./images/licenses_placeholder.png' alt='placeholder' className='licenseeImg'/>
                </div>
                <div className='licenseTablecellb'>
                    LOREM IPSUM
                    <p className='licenseTablecell-subtext'>Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed do
eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default LicensesBody