import React from 'react'
import '../css/Whatwedo.css';

function Whatwedo() {
  return (
    <>
    <div className='whatwedo'>
      <p className='what-text-title'>Introducing LUCKYGIVING</p>
      <p className='text-center'>
        <p className='text-center'>
        Exciting Fundraising with a Giving Twist!
        </p>
       <p className='text-center-bold'>Be a LUCKYGIVER!</p>

       <p className='text-center'>Join our lottery draws for a shot at cash prizes while supporting worthy charities!</p>

       <p className='text-center-bold'>Give and Have Fun! </p>

       <p className='text-center'>Every project receives 50% of contributions from Luckygivers in our daily draw.</p>
      </p>
    </div>
    </>
  )
}

export default Whatwedo