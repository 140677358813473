import React from 'react'
import ComingSoon from '../screens/ComingSoon'

function Topup() {
  return (
    <>
    <ComingSoon title='Catch the top up soon.'/>
   </>
  )
}

export default Topup