import React,{useState,useEffect} from 'react'
import { useParams, Link } from "react-router-dom";
import Countdown from '../screens/Countdown'
import Footer from '../screens/Footer'
import '../css/Project.css';
import {Domain_Url} from '../mocks/Constants';
import axios from "axios";
import Thankyou from '../screens/Thankyou'
import LuckyGivingCircle from '../screens/LuckyGivingCircle'

function SupportedProjectDetails() {
    const fname = localStorage.getItem("fname");
    const [isTotal, setTotal] = useState(false)
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("userId");
    const { id } = useParams();
    const [responseData, setResponseData] = useState([])
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var myDonation = 0
    const [projectName, setPname] = useState()
    const [projectSub, setPsubtitle] = useState()
    const [projectDesc, setPDesc] = useState()
    const [projectBody, setPbody] = useState()
    const [myCounter, setmCounter] = useState(0)
    useEffect(() => {
        const url = Domain_Url.LuckyGiving+"/api/findSupportedDetails";
        const data = {
          user_id: user_id,
          pid: id,
        }
              axios({
   
                // Endpoint to send files
                url: url,
                method: "POST",
                headers: {
                    // Add any auth token here
                    authorization: token,
                },
     
                // Attaching the form data
                data: data,
            })
     
                // Handle the response from backend here
                .then((res) => {
                  //alert(res.data.projects[0].totalAmount)
                  // var tD = 0
                  setmCounter(res.data.projects.length)
                  res.data.projects.map((item,index) => {
                    
                   //myCounter += 1
                    //setResponseData(item)
                    if(index === res.data.projects.length - 1) {
                      setResponseData(res.data.projects)
                      console.log("Projects lyndon",res.data.projects)
                     
                    }
                  })
                 
                })
                // Catch errors if any
                .catch((err) => {
                  //alert("Error")
                 });
//get project details
const murl = Domain_Url.LuckyGiving+"/api/projects";
const mdata = {
  user_id: user_id,
  pid: id,
}
      axios({

        // Endpoint to send files
        url: murl,
        method: "POST",
        headers: {
            // Add any auth token here
            authorization: token,
        },

        // Attaching the form data
        data: mdata,
    })

        // Handle the response from backend here
        .then((res) => {
          //alert(res.data.projects[0].totalAmount)
          // var tD = 0
          //res.data.projects.map((item,index) => {
            console.log("Project Details",res.data.projects[0])
            setPname(res.data.projects[0].name)
            setPDesc(res.data.projects[0].description)
            setPsubtitle(res.data.projects[0].subTitle)
            setPbody(res.data.projects[0].body)
          //})
         
        })
        // Catch errors if any
        .catch((err) => {
          //alert("Error")
         });         
      }, [])

      function formatDateFullMonth(date) {
        //const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const options = { month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
      }
      const myMargin = myCounter * 27 + "px"
      const dynamicStyle = {
        // backgroundColor: 'blue',
        // color: 'white',
        // padding: '10px',
        // borderRadius: '5px',
        marginTop: myMargin,
      };
  return (
    <>
         <div className='hello-projects'>
            <div className='hello-box'>
                <div className='hello-project-row'>
                <div className='hello-project-column'>
                    <div className='hello-name'>Hello {fname},</div>
                    <div className='member-since'>Member since 2022</div>
                </div>
                <div className='project-supported-box'>
                    <div className='hello-project-row'>
                    <div className='hello-project-column-supported-details'>
                        <h2 className='project-supported-headtitle'>DATE</h2>
                        {responseData.map((items, index) => {
                        return <div className={index % 2 === 0 ? "supported-bg" : "supported-bg-none"}>{formatDateFullMonth(items.created_at)}</div>
                    }).reverse()} 
                    </div>
                    <div className='hello-project-column-supported-details'>
                        <h2 className='project-supported-headtitle'>AMOUNT DONATED</h2>
                        
                        {responseData.map((items, index) => {
                        return <div className={index % 2 === 0 ? "supported-bg-money" : "supported-bg-none-money"}>&euro;{items.bet_fee/2}</div>
                    }).reverse()} 
                    </div>
                    <div className='hello-project-column-supported-details'><h2 className='project-supported-headtitle'>TRANSACTION ID</h2>
                        {
                        
                        responseData.map((items,index) => {
                            
                            return <div className={index % 2 === 0 ? "supported-bg" : "supported-bg-none"}>{items._id}</div>
                            
                        }).reverse()

                        } 

                    </div>
                    <div className='hello-project-column-supported-details'><h2 className='project-supported-headtitle'>STATUS</h2>
                        {
                        
                        responseData.map((items,index) => {
                                    var donation = parseInt(items.totalAmount)/2
                            
                            return <div className={index % 2 === 0 ? "supported-bg" : "supported-bg-none"}>{items.status}</div>
                            
                        }).reverse()

                        } 

                    </div>
                </div>
                </div>
                <div className='hello-project-column'>
                    <div className='total-amount-donated'>TOTAL AMOUNT DONATED</div>
                    <div className='total-amount-donated-price'>€ {
                    
                    responseData.map((items,index) => {
                        var donation = parseInt(items.bet_fee)/2
                        myDonation+= donation
                    }).reverse()
                    
                    } {myDonation.toFixed(2)}</div>
                </div>
                
                </div>
            </div>
            </div>
        <div style={dynamicStyle} className='supportedDetails'>
             <div className='hello-project-row'>
                <div className='hello-project-column-60'>
                    <h1 className='supported-h1 '>{projectName}</h1>
                    <h2 className='supported-h2'>{projectSub}</h2>
                    <p>{projectDesc}</p>
                    {projectBody}
                </div>
                <div className='hello-project-column-40'>
                <table>
                        <tr>
                        <td align='center'>
                        <LuckyGivingCircle />
                            {/* <div className='circle-title'>
                                Lucky Giving
                                <div className='text-border2'>6/45</div>
                            </div> */}
                        </td>
                        </tr>
                        <tr>
                        <td>
                        <p><Link to='/play'><button className='results-pill-button'>PLAY NOW</button></Link></p>
                        </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <Thankyou text="THANK YOU FOR YOUR SUPPORT"/>
        <Footer/>
    </>
  )
}

export default SupportedProjectDetails