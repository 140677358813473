import React from 'react'
import Privacybanner from '../screens/Privacybanner'
import LicensesBody from '../screens/LicensesBody'
import Footer from '../screens/Footer'
import ContactForm from '../screens/ContactForm';
function Licenses() {
  //const user = localStorage.getItem("token");
  return (
    <>
        {/* <Privacybanner 
            title='Our Licenses'
            lead='The LUCKYGIVING PROGRAM is operated by NCGAC Limited under its Interactive Gaming License (IGL) issued by the Cagayan Economic Zone Authority (CEZA) in the Philippines.'
            bodyText={<LicensesBody/>}
        /> */}
        <div className='privacyBannerHolder'>
            <div className='BannerTitle'>Our Licenses</div>
            <div className='leadText'>The LUCKYGIVING PROGRAM is operated by NCGAC Limited under its Interactive Gaming License (IGL) issued by the Cagayan Economic Zone Authority (CEZA) in the Philippines.</div>
            <div className='leadText'>CEZA is the first online gaming jurisdiction in Asia.</div>
            <div className='leadText-red'>https://ceza.gov.ph/</div>
        </div>
        <ContactForm />
        <Footer/>
    </>
  )
}

export default Licenses