import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../css/verify.css';
import {Domain_Url} from '../mocks/Constants';
import axios from "axios";
import Login from '../pages/Login';
import AlertLoading from "./AlertLoading";
import Alert from '../pages/Alert';


function VerifyAccount() {
    const [one, setOne] = useState("");
    const [two, setTwo] = useState("");
    const [three, setThree] = useState("");
    const [four, setFour] = useState("");
    const [five, setFive] = useState("");
    const [six, setSix] = useState("");
    const { id } = useParams();
    const [count, setCount] = useState(0);
    const [showLogin, setShowLogin] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertImage, setAlertImage]= useState(Domain_Url.Localhost+"./images/red-check.png")
    const [alertTitle, setAlertTitle] = useState("SUCCESSFUL")
    const [alertMessage, setAlertMessage] = useState("Your account has been verified.")
    const [alertMessagePart, setAlertmPart] = useState("Please login to your account")
    

    const handleChange =(e)=>{
        // Here we are checking if the length is equal to 1
        if(e.target.value.length===1){ 
            
          if (e.target.name === "one") {
            setOne(e.target.value);
            document.getElementById('two').focus();
          }
          else if (e.target.name === "two") {
            setTwo(e.target.value);
            document.getElementById('three').focus();
          }
          else if (e.target.name === "three") {
            setThree(e.target.value);
            document.getElementById('four').focus();
          }
          else if (e.target.name === "four") {
            setFour(e.target.value);
            document.getElementById('five').focus();
          }
          else if (e.target.name === "five") {
            setFive(e.target.value);
            document.getElementById('six').focus();
          } else {
            setSix(e.target.value);
            document.getElementById('btnVerify').disabled = false;
            document.getElementById('btnVerify').focus();
          }
        } else {
            e.target.value = "";
        }
        
      }

      const handleVerifyForm = async (e) => {
        e.preventDefault();
          setLoading(true)
          try {
            const data = {
              user_id : id,
              otp: one+two+three+four+five+six
            }          
            const url = Domain_Url.DevServer+"/api/emailverify/"+id;
            const { data: res } = await axios.post(url, data);
            //alert(res.message)
            setLoading(false)
            setShowAlert(true)
            setTimeout(() => {
              setShowAlert(false)
             
            }, 3000);
            setShowLogin(true)
            //navigate(`/verify/${res.user_id}`);
            //window.location.reload();
            console.log(res.message);
            // console.log(res.user_id);
            
          } catch (error) {

        }
    }

    useEffect(() => {
      setCount((count) => count + 1);
      if (count > 1) {
        setShowLogin(true);
      } else {
        setShowLogin(false);
      }
    }, [showLogin])
  
    const handleShowLogin = () => {
      if (showLogin) {
        setShowLogin(false);
      }
      else {
        setShowLogin(true);
      }
    }
    const [loading, setLoading] = useState(false);
        const handleShowSpinner = () => {
            if (loading) {
                setLoading(false);
            }
            else {
                setLoading(true);
            }
          }
    const handleShowAlert = () => {
            if (showAlert) {
                setShowAlert(false);
            }
            else {
                setShowAlert(true);
            }
          }
  return (
    <>
    <div className='joinHolder'>
        <div className='row'>
            <form id='formData' onSubmit={handleVerifyForm}>
                <div className='verify-title'>VERIFY ACCOUNT</div>
                <div className='verify-text'> Almost there, please enter the code we've sent to your email.</div>
                <div className='verify-input-holder'>
                    <input type='number' className='verify-input' name="one" id='one'
                    value={one}
                    onChange={ handleChange}
                    onFocus={setOne}/> 
                    <input type='number' className='verify-input' name='two' id='two'
                    value={two}
                    onChange={ handleChange}
                    onFocus={setTwo}/> 
                    <input type='number' className='verify-input' name='three' id='three'
                    value={three}
                    onChange={ handleChange}
                    onFocus={setThree}/> 
                    <input type='number' className='verify-input' name='four' id='four'
                    value={four}
                    onChange={ handleChange}
                    onFocus={setFour}/> 
                    <input type='number' className='verify-input' name='five' id='five'
                    value={five}
                    onChange={ handleChange}
                    onFocus={setFive}/> 
                    <input type='number' className='verify-input' name='six' id='six'
                    value={six}
                    onChange={ handleChange}
                    onFocus={setSix}/>
                </div>
                <div className='pillHolder'><button className='verify-pill-button' id='btnVerify' onClick={handleVerifyForm} disabled>Verify</button></div>
                <div className='send-again'>Didn't receive your code? <Link>Send it again</Link></div>
            </form>
        </div>
    </div>
    <Login isOpen={showLogin} onRequestClose={handleShowLogin} title='Account Activated'/>
    <AlertLoading isOpen={loading} onRequestClose={handleShowSpinner}/>
    <Alert isOpen={showAlert} onRequestClose={handleShowAlert}
            src= {alertImage}
            title={alertTitle}
            message={alertMessage}
            messagePart={alertMessagePart}
            isRefresh={false}
        />
    </>
  )
}

export default VerifyAccount