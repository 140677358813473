import React, {useState} from 'react'
import '../css/Reset.css';
import AlertLoading from "./AlertLoading";
import axios from "axios";
import {Domain_Url} from '../mocks/Constants';
import { useParams } from "react-router-dom";
import Alert from './Alert';

function ResetPassword() {
  const {id} = useParams()
  const [passwordClass, setPassword] = useState(true);
  const [cpasswordClass, setcPassword] = useState(true);
  const [error, setError] = useState("*must include 8 characters, 1 uppercase, 1 lowercase and 1 special character");
  const [pwd, showPwd] = useState(false);
  const [cpwd, cshowPwd] = useState(false);
  const [mdata, setData] = useState({
    password: "",
    confirmpassword: "",
    });

    const handleLoginChange = ({ currentTarget: input }) => {
    setData({ ...mdata, [input.name]: input.value });
    };

const handleSubmit = async (e) => {

    e.preventDefault();
    setPassword(true);
    setcPassword(true);

     if (mdata.password === "") {
        setPassword(false);
        if (mdata.confirmpassword === "") {
            setcPassword(false);
        }
    }
    else if (mdata.confirmpassword === "") {
        setcPassword(false);
    }

    else if (mdata.password !== mdata.confirmpassword) {
        setcPassword(false);
        setError("Password does not match")
    }
    
    else {
        setLoading(true)
        const data = {
            user_id: id,
            password: mdata.password
        }
        try {
            const url = Domain_Url.DevServer+"/api/resetPassword";
            const { data: res } = await axios.post(url, data);
            setLoading(false)
            //alert(res.message);
            setShowAlert(true)
            //navigate(`/verify/${res.user_id}`);
            //window.location.reload();
            // console.log(res.message);
            // console.log(res.user_id);
            
        } catch (error) {
            setLoading(false)
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
               // alert(error.response.data.message);
                setError(error.response.data.message);
               
            }
        }
    }
}
    const [loading, setLoading] = useState(false);
      const handleShowSpinner = () => {
        if (loading) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
      }
    const [showAlert, setShowAlert] = useState(false);
    const handleShowAlert = () => {
        if (showAlert) {
            setShowAlert(false);
        }
        else {
            setShowAlert(true);
        }
    }

    const handleClick = () => {
        if (pwd) {
            showPwd(false);
        } else {
            showPwd(true);
        }
    }

    const handleClickConfirm = () => {
        if (cpwd) {
            cshowPwd(false);
        } else {
            cshowPwd(true);
        }
    }

  return (
    <>
        <div className='resetHolder'>
            <div className='reset-title'>UPDATE PASSWORD</div>
            <div className='loginHolder'>
                <div className='reset-inputHolder'>
                    <div className={passwordClass ? "input-icons" : "input-iconsRed"}>
                            {pwd ? <img src='../images/green_eye.png' onClick={handleClick} className='eyeClick'/> : <img src='../images/viewpassword_green.png' onClick={handleClick} className='eyeClick'/>}
                            <input placeholder='Password' type={pwd ? 'text' : 'password'} name="password"
                                    className={passwordClass ? "inputGreen" : "inputRed"}
                                    onChange={handleLoginChange}
                                    value={mdata.password}
                                    required/>
                    </div>
                </div>
                {/* <input type='text' placeholder='Password' 
                        className={passwordClass ? "reset-inputGreen" : "reset-inputRed"}
                        onChange={handleLoginChange}
                        value={mdata.password}
                        name='password'
                    /> */}
            </div>
            
            <div className='reset-100-error'><span className={passwordClass ? "hideDiv" : "showDiv"}>*Please provide valid password</span></div>
            <div className='loginHolder'>
                <div className='reset-inputHolder'>
                    <div className={cpasswordClass ? "input-icons" : "input-iconsRed"}>
                        {cpwd ? <img src='../images/green_eye.png' onClick={handleClickConfirm} className='eyeClick'/> : <img src='../images/viewpassword_green.png' onClick={handleClickConfirm} className='eyeClick'/>}
                            <input placeholder='Confirm Password' type={cpwd ? 'text' : 'password'} name="confirmpassword"
                                className={cpasswordClass ? "inputGreen" : "inputRed"}
                                onChange={handleLoginChange}
                                value={mdata.confirmpassword}
                            />
                    </div>
                </div>    
                {/* <input type='text' placeholder='Confirm Password' 
                        className={cpasswordClass ? "reset-inputGreen" : "reset-inputRed"}
                        onChange={handleLoginChange}
                        value={mdata.confirmpassword}
                        name='confirmpassword'
                    /> */}
            </div>
            <div className='reset-100-error'><span className={cpasswordClass ? "hideDiv" : "showDiv"}>{error}</span></div>
            <div className='loginHolder'>
                <button type='submit' className='btn-submit' onClick={handleSubmit}>UPDATE PASSWORD</button>
            </div>
        </div>
        <AlertLoading isOpen={loading} onRequestClose={handleShowSpinner}/>
        <Alert isOpen={showAlert} onRequestClose={handleShowAlert}
            src='../images/red-check.png'
            title='SUCCESSFUL'
            message="Reset password successful!"
            messagePart="You may login to your account now."
            isRefresh="true"
        />
    </>
  )
}

export default ResetPassword