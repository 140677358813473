import React from 'react'
import ComingSoon from '../screens/ComingSoon';

function Entries() {
  return (
    <>
    <ComingSoon title='Catch the my entries soon.'/>
   </>
  )
}

export default Entries