import React from 'react';
import Navbar from './components/screens/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter , Routes, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';
import Draw from './components/pages/Draw';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsConditions from './components/pages/TermsConditions';
import Licenses from './components/pages/Licenses';
import NotFound from './components/pages/NotFound';
import Login from './components/pages/Login';
import Contactus from './components/screens/Contactus';
import ProjectDetailView from './components/pages/ProjectDetailView'
import Faq from './components/pages/Faq';
import HomeComingSoon from './components/screens/HomeComingSoon';
import Mechanics from './components/pages/Mechanics';
import VerifyAccount from './components/pages/VerifyAccount';
import Play from './components/pages/Play';
import SupportedProjects from './components/pages/SupportedProjects';
import SupportedProjectDetails from './components/pages/SupportedProjectDetails';
import Profile from './components/pages/Profile';
import Entries from './components/pages/Entries';
import Topup from './components/pages/Topup';
import History from './components/pages/History';
import ResetPassword from './components/pages/ResetPassword';

function App() {
  const user = localStorage.getItem("token");

 
  return (
    <>
      <BrowserRouter>
       {/* {<Navbar/>} */}
        <Routes>
          <Route path='*' element={<HomeComingSoon/>} />
	  {/* {user && <Route path="/" exact element={<Home/>} />}
          {<Route path='/' exact element={<Home/>} />}
          <Route path='/645' element={<Services/>} />
          <Route path='/projects/:id/:label' element={<ProjectDetailView/>} />
          <Route path='/projects' element={<Products/>} />
          <Route path='/supportedprojects' element={<SupportedProjects/>} />
          <Route path='/supportedprojects/:id' element={<SupportedProjectDetails/>} />
          <Route path='/sign-up' element={<SignUp/>} />
          <Route path='/resetpassword/:id' element={<ResetPassword/>} />
          <Route path='/draw' element={<Draw/>} />
          <Route path='/profile' element={<Profile/>} />
          <Route path='/entries' element={<Entries/>} />
          <Route path='/topup' element={<Topup/>} />
          <Route path='/history' element={<History/>} />
          <Route path='/faq' element={<Faq/>} />
          <Route path='/privacypolicy' element={<PrivacyPolicy/>} />
          <Route path='/termsconditions' element={<TermsConditions/>} />
          <Route path='/licenses' element={<Licenses/>} />
          <Route path='/contactus' element={<Contactus/>} />
          <Route path='/mechanics' element={<Mechanics/>} />
          <Route path='/verify/:id' element={<VerifyAccount/>} />
          <Route path='/play' element={<Play/>} />
          <Route path='*' element={<NotFound/>} /> */}
        </Routes>
      <Login title='WELCOME BACK'/>
      </BrowserRouter>
    </>
  );
}

export default App;
