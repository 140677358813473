import React from 'react';
import { useCountdown } from '../../hooks/useCountdown';
import DateTimeDisplay from './DateTimeDisplay';
import '../css/Countdown.css';


const ExpiredNotice = () => {
    return (
      <div className="expired-notice">
        <span>Expired!!!</span>
        <p>Please select a future date and time.</p>
      </div>
    );
  };

  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    //hours
    var hr = String(hours);
    var hr1 = 0;
    var hr2 = 0;
    if (hr.length > 1) {
      hr1 = hr.substr(0,1);
      hr2 = hr.substr(1);
    } else {
      hr1 = 0;
      hr2 = hr.substr(0,1);
    }
    //minutes
    var min = String(minutes);
    var min1 = 0;
    var min2 = 0;
    if (min.length > 1) {
      min1 = min.substr(0,1);
      min2 = min.substr(1);
    } else {
      min1 = 0;
      min2 = min.substr(0,1);
    }
    //seconds
    var sec = String(seconds);
    var sec1 = 0;
    var sec2 = 0;
    if (sec.length > 1) {
      sec1 = sec.substr(0,1);
      sec2 = sec.substr(1);
    } else {
      sec1 = 0;
      sec2 = sec.substr(0,1);
    }
    return (
      <div className="show-counter">
          <div className='countdown-link'>
          {/* <DateTimeDisplay value={sec.length} type={'Days'} isDanger={days <= 3} /> */}
          
          <DateTimeDisplay value={hr1} type={'Hours'} isDanger={false} />
          <DateTimeDisplay value={hr2} type={'Hours'} isDanger={false} />
          <p className='dot'>:</p>
          <DateTimeDisplay value={min1} type={'Mins'} isDanger={false} />
          <DateTimeDisplay value={min2} type={'Mins'} isDanger={false} />
          <p className='dot'>:</p>
          <DateTimeDisplay value={sec1} type={'Seconds'} isDanger={false} />
          <DateTimeDisplay value={sec2} type={'Seconds'} isDanger={false} />
          </div>
      </div>
    );
  };
  

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
