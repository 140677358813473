import React from 'react'
import Mainbanner from '../screens/Mainbanner'
import Project from '../screens/Project'
import Countdown from '../screens/Countdown'
import Results from '../screens/Results'
import Whatwedo from '../screens/Whatwedo'
import Thankyou from '../screens/Thankyou'
import Footer from '../screens/Footer'

function Home() {
  return (
	<>
    <Mainbanner/>
    <Project/>
    <Countdown/>
    <Results/>
    <Whatwedo/>
    <Thankyou text="THANK YOU FOR YOUR SUPPORT"/>
    <Footer/>
  </>
  )
}

export default Home