import React, {useState, useEffect} from 'react'
import '../css/NavProfile.css'
import { Link } from 'react-router-dom';
import AlertLogout from '../pages/AlertLogout';

function NavProfile() {
    const removeToken = () => {
        localStorage.removeItem("token");
    };
    const [count, setCount] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    
    useEffect(() => {
        setCount((count) => count + 1);
        if (count > 1) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
      }, [showAlert])

    const handleShowAlert = () => {
        if (showAlert) {
            setShowAlert(false);
        }
        else {
            setShowAlert(true);
        }
      }
  return (
    <>
        {/* <div className='profile-holder'>
            <Link><img src='./images/profile.png' className='profile-img' alt='Profile Image'/></Link>
            <div className='profile-menu'>
                text1<br/>
                text2
            </div>
        </div> */}
        <div className='profile-holder'>
            <div className="dropdown">
                <button className="dropbtn">
                    <img src='./images/profile.png' className='profile-img' alt='Profile'/>
                </button>
                <div className="dropdown-content">
                <Link className='profileLink' to='profile'>MY PROFILE</Link>
                <Link className='profileLink' to='supportedprojects'>SUPPORTED PROJECTS</Link>
                <Link className='profileLink' to='entries'>MY ENTRIES</Link>
                <Link className='profileLink' to='topup'>TOP UP</Link>
                <Link className='profileLink' to='history'>ENTRY HISTORY</Link>
                <Link className='profileLink' onClick={handleShowAlert}>LOGOUT</Link>
                </div>
            </div> 
        </div>
        <AlertLogout isOpen={showAlert} onRequestClose={handleShowAlert}
            title='WARNING'
            message="Are you sure you want to logout?"
        />
    </>
  )
}

export default NavProfile