import React from 'react'
import { Link } from 'react-router-dom';

function ContactForm() {
  return (
    <>
        <div className='contactUsHolder'>
            <div className='contactHeader'>DIDN’T FIND AN ANSWER TO YOUR QUESTION?</div>
            <div className='contactText'>Get in touch with us and our Customer Service team will be happy to further assist you.</div>
            <div className='pillHolder'><Link to='/contactus'><button className='results-pill-button'>CONTACT US</button></Link></div>
        </div>
    </>
  )
}

export default ContactForm