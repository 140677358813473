import React from 'react';
import '../../App.css';
import ComingSoon from '../screens/ComingSoon';
import Mainbanner from '../screens/Mainbanner'
import Project from '../screens/Project'
import Footer from '../screens/Footer'
import HowItWork from '../screens/HowItWork'

export default function Products() {
  // return <h1 className='products'>PRODUCTS</h1>;
  return (
    <>
     {/* <ComingSoon title='Every bet counts. Stay tuned as we’ve partnered with amazing organizations.'/> */}
      <Mainbanner/>
      <Project/>
      <div className='Video'>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/2YbO-yztOxA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <HowItWork/>
      <Footer/>
    </>
  )
}
