import React from 'react'
import '../../App.css';
import PlayBanner from '../screens/PlayBanner'
import PlayBetArea from '../screens/PlayBetArea'
import Footer from '../screens/Footer';
import Thankyou from '../screens/Thankyou'

function Play() {
  return (
    <>
        <PlayBanner/>
        <PlayBetArea/>
        <Thankyou text="GOOD LUCK!"/>
        <Footer/>
   </>
  )
}

export default Play