exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;400;700&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Baloo+Tamma+2:wght@400;600;700;800&family=Montserrat:wght@200;400;700&family=Poppins:wght@200;400;700&display=swap);", ""]);

// module
exports.push([module.id, "\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  font-family: 'PT Sans', sans-serif;\n}\n\n.Video {\n  position:absolute; \n  width:100%;\n  margin: 0 auto;\n  text-align: center;\n}\n\n.home,\n.services,\n.products,\n.sign-up {\n  display: flex;\n  height: 90vh;\n  align-items: center;\n  justify-content: center;\n  font-size: 3rem;\n}\n\n.countdownHolder {\n  padding: 20px;\n  background-color: #446747;\n}\n\n.results {\n  display: flex;\n  height: 30vh;\n  align-items: center;\n  justify-content: center;\n  font-size: 3rem;\n  background-color: #FFF1E6;\n}\n\n.services {\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  color: #000;\n  font-size: 100px;\n}\n\n.products {\n  background-position: center;\n  background-size: fill;\n  background-repeat: no-repeat;\n  color: #000;\n  font-size: 100px;\n}\n\n.sign-up {\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  color: #000;\n  font-size: 100px;\n}\n\n.whatwedo {\n  background-position: center;\n  background-size: fill;\n  background-repeat: no-repeat;\n  color: #000;\n  /* height: 25vh; */\n  text-align: center;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.thankyou {\n  background-position: center;\n  background-size: fill;\n  background-repeat: no-repeat;\n  background-color: #446747;\n  padding: 15px;\n  display:flex;\n  align-items:center;\n}\n.footer {\n  background-position: center;\n  background-size: fill;\n  background-repeat: no-repeat;\n  background-color: #fff;\n  color: #fff;\n  font-size: 40px;\n  height: 25vh;\n  text-align: center;\n}\n@media screen and (max-width: 820px) {\n  .thankyou {\n    background-position: center;\n    background-size: fill;\n    background-repeat: no-repeat;\n    background-color: #446747;\n    height: 70px;\n    display:flex;\n    align-items:center;\n  }\n}", ""]);

// exports
