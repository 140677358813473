import React, {useState, useEffect} from 'react'
import '../css/Join.css';
import PhoneInput from 'react-phone-number-input';
import axios from "axios";
import Alert from '../pages/Alert';
import {Domain_Url} from '../mocks/Constants';
import validator from 'validator';

function Contactus() {
    
    const [count, setCount] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    
    useEffect(() => {
        setCount((count) => count + 1);
        if (count > 1) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
      }, [showAlert])

    const handleShowAlert = () => {
        if (showAlert) {
            setShowAlert(false);
        }
        else {
            setShowAlert(true);
        }
      }

    const [phonevalue, setValue] = useState("");
    const whitelisted_conutries = ['WS',  'SB',  'TK',  'TO',  'TV',  'VU',  'WF',  'HK', 'PH'];
    const [phoneClass, setPhone] = useState(true);
    const [subjvalue, setSubjectValue] = useState("");
    const [fnameClass, setFname] = useState(true);
    const [emailClass, setEmail] = useState(true);
    const [subjectClass, setSubject] = useState(true);
    const [messageClass, setMessage] = useState(true);

    const handleSubjectChange = (e) => {
        setSubjectValue(e.target.value);
      };

    const [data, setData] = useState({
        fullName: "",
        phoneNumber: phonevalue,
        email: "",
        subject: "",
        message: "",
    });

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };

    const handleSubmit = async (e) => {
        data.phoneNumber = phonevalue;
        data.subject = subjvalue;
        e.preventDefault();
        setDataActive()
        if (data.fullName === "") {
            setFname(false);
        }
        else if (!validator.isEmail(data.email)) {
            setEmail(false);
        }
        else if (data.phoneNumber === "") {
            setPhone(false);
        }
        else if (data.subject === "") {
            setSubject(false);
        }
        else if (data.message === "") {
            setMessage(false);
        }
        else {
            try {
                
                const url = Domain_Url.LocalhostServer+"/api/contactus";
                const { data: res } = await axios.post(url, data);
                handleShowAlert();
                //navigate("/login");
                console.log(data);
                console.log(res.message);    
                cleanData();        
            } catch (error) {
                if (
                    error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) {
                    //setError(error.response.data.message);
                    
                    if(error.response.data.message === '"email" must be a valid email') {
                        alert(emailClass)
                        //setEmail(false)
                    }
                }
            }
        }
    }

    const cleanData = ()=> {
        document.getElementById("fullName").value = "";
        document.getElementById("email").value = ""; 
        document.getElementById("phoneNumber").value = "";
        document.getElementById("subject").value = "";
        document.getElementById("message").value = "";
    }

    const setDataActive = () => {
        setFname(true);
        setEmail(true);
        setPhone(true);
        setSubject(true);
        setMessage(true);
    }

    

  return (
    <>
        <div className='joinHolder'>
            <div className='row'>
                <form id='formData' onSubmit={handleSubmit}>
                    <div className='column-100-title'>GET IN TOUCH</div>
                    <div className='column-50-row'>
                        <input type='text' placeholder='Name' name="fullName" id="fullName" className={fnameClass ? "inputGreen" : "inputRed"}
							onChange={handleChange}
							value={data.fullName}
							required/>
                    </div>
                    <div className='column-50-row-error'><span className={fnameClass ? "hideDiv" : "showDiv"}>*Please provide your complete name</span></div>
                    <div className='column-50-row'>
                        <input type='text' placeholder='Email' name="email" id="email" className={emailClass ? "inputGreen" : "inputRed"}
							onChange={handleChange}
							value={data.email}
							required/>
                    </div>
                    <div className='column-50-row-error'><span className={emailClass ? "hideDiv" : "showDiv"}>*Please provide valid email address</span></div>
                    <div className='column-50-row'>
                        <PhoneInput
                        placeholder="Phone number"
                        defaultCountry="PH"
                        name='phoneNumber'
                        id='phoneNumber'
                        countries={whitelisted_conutries}
                        international
                        countryCallingCodeEditable={false}
                        initialValueFormat="national"
                        value={phonevalue}
                        className={phoneClass ? "inputTelGreen" : "inputTelRed"}
                        onChange={setValue}
                        />
                    </div>
                    <div className='column-50-row-error'><span className={phoneClass ? "hideDiv" : "showDiv"}>*Invalid format</span></div>
                    <div className='column-50-row'>
                        <select value={subjvalue} onChange={handleSubjectChange} name="subject" id="subject" className={subjectClass ? "selectGreen" : "selectRed"}
						required>
                        <option value="">Subject</option>
                        <option value="Inquiry">Inquiry</option>
                        <option value="Payment">Payment</option>
                        <option value="Projects">Projects</option>
                        <option value="Draws and Winnings">Draws and Winnings</option>
                        <option value="Others">Others</option>
                        </select>
                    </div>
                    <div className='column-50-row-error'><span className={subjectClass ? "hideDiv" : "showDiv"}>*Please select a subject</span></div>
                    <div className='column-50-row'>
                        <textarea placeholder='Your Message' name='message' id='message' className={messageClass ? "inputGreenTextArea" : "inputRedTextArea"}
							onChange={handleChange}
							value={data.message}
							required></textarea>
                    </div>
                    <div className='column-50-row-error'><span className={messageClass ? "hideDiv" : "showDiv"}>*Please input your message</span></div>
                    <div className='column-100'>
                        <button type='submit' className='btn-send' onClick={handleSubmit}>Send</button>
                    </div>
                </form>
            </div>
        </div>
        <Alert isOpen={showAlert} onRequestClose={handleShowAlert}
            src='./images/message.png'
            title='EMAIL SENT'
            message="Thank you for contacting us."
            messagePart="We'll get in touch with you soon."
            isRefresh='true'
        />
    </>
  )
}

export default Contactus