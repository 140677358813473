import React, {useState, useEffect} from 'react'
import Modal from 'react-modal/lib/components/Modal'
import { Link } from 'react-router-dom';
import '../css/Login.css';
import validator from 'validator';
import axios from "axios";
import {Domain_Url} from '../mocks/Constants';
import Alert from './Alert';
import AlertLoading from "./AlertLoading";


function Login(props) {
    const [modalIsOpen, setIsOpen] = useState({...props.isOpen});
    const [modalIsForgot, setIsForgot] = useState({...props.isForgot});
    const [passwordClass, setPassword] = useState(true);
    const [emailClass, setEmail] = useState(true);
    const [count, setCount] = useState(0);
    const [error, setError] = useState("*must include 8 characters, 1 uppercase, 1 lowercase and 1 special character");
    const [myTitle, setTitle] = useState("WELCOME BACK")
   
    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen])

    useEffect(() => {
        setIsForgot(props.isForgot);
    }, [props.isForgot])

    const afterOpenModal=() => {
        // references are now sync'd and can be accessed.
    
      }
    
    const closeModal=() => {
        setIsOpen(false)
        setIsForgot(false)
      }

    const showForgot=() => {
        setIsOpen(false)
        setIsForgot(true)
    }
    const [showAlert, setShowAlert] = useState(false);
    
    useEffect(() => {
        setCount((count) => count + 1);
        if (count > 1) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
      }, [showAlert])
    
      const [data, setData] = useState({
        email: "",
        password: "",
    });

    const handleLoginChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validator.isEmail(data.email)) {
            setEmail(false);
        }
        else if (data.password === "") {
            setPassword(false);
        }
        else {
            setLoading(true)
            //send data to server
            try {
                    
                const url = Domain_Url.LuckyGiving+"/api/auth";
                const { data: res } = await axios.post(url, data);
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("fname", res.data.fname);
                localStorage.setItem("userId", res.data.userId);
                setLoading(false)
			    window.location = "/";
                
            } catch (error) {
                setLoading(false)
                if (
                    error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) {
                    setPassword(false);
                    setError(error.response.data.message);
                }
            }
        }
      }

    const handleResetPassword = async () => {
        if (!validator.isEmail(data.email)) {
            setEmail(false);
        } else {
            setEmail(true);
            closeModal()
            handleShowAlert()
            //send email to reset password
            //send data to server
            const mydata = {
                email: data.email,
              }
            try {
                    
                const url = Domain_Url.LuckyGiving+"/api/forgotpassword";
                const { mydata: res } = await axios.post(url, mydata);
                
            } catch (error) {
                if (
                    error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) {
                    setPassword(false);
                    setError(error.response.data.message);
                }
            }
        }
    }

    const handleShowAlert = () => {
        if (showAlert) {
            setShowAlert(false);
        }
        else {
            setShowAlert(true);
        }
      }

      const [loading, setLoading] = useState(false);
      const handleShowSpinner = () => {
        if (loading) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
      }
  return (
    <>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            className='modalBody'
        >
            
            <div className='closeHolder'><Link onClick={closeModal}>X</Link></div>
            <form onSubmit={handleSubmit}>
                <div className='passResetHolder'><label className='resetLabel'>{myTitle}</label></div>
                <div className='loginHolder'>
                    <input type='text' placeholder='Email' 
                        className={emailClass ? "inputLogin" : "inputLogin-red"}
                        onChange={handleLoginChange}
                        value={data.email}
                        name='email'
                    />
                </div>
                <div className='login-100-error'><span className={emailClass ? "hideDiv" : "showDiv"}>*Please provide valid email address</span></div>
                <div className='loginHolder'>
                    <input type='password' placeholder='Password' 
                        className={passwordClass ? "inputLogin" : "inputLogin-red"}
                        onChange={handleLoginChange}
                        value={data.password}
                        name='password'
                    />
                </div>
                <div className='login-100-error'><span className={passwordClass ? "hideDiv" : "showDiv"}>{error}</span></div>
                <div className='loginHolder'>
                    <button type='submit' className='btn-login'>Log In</button>
                </div>
                <div className='forgotHolder'>
                 <Link onClick={showForgot}>Forgot Password?</Link>
                </div>
            </form>
        </Modal>
        {/* Password reset */}
        <Modal
            isOpen={modalIsForgot}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            className='modalBody'
        >
            
            <div className='closeHolder'><Link onClick={closeModal}>X</Link></div>
            <form onSubmit={handleSubmit}>
                <div className='passResetHolder'>
                    <label className='resetLabel'>PASSWORD RESET</label>
                </div>
                <div className='passResetHolder'>Enter your email address that you used to register. We'll send you an email link to reset your password.</div>
                <div className='loginHolder'>
                    <input type='text' placeholder='Email' 
                        className={emailClass ? "inputLogin" : "inputLogin-red"}
                        onChange={handleLoginChange}
                        value={data.email}
                        name='email'
                    />
                </div>
                <div className='login-100-error'><span className={emailClass ? "hideDiv" : "showDiv"}>*Please provide valid email address</span></div>
                
                <div className='passResetHolder'>
                    <button className='btn-login' onClick={handleResetPassword}>Reset Password</button>
                </div>
                
            </form>
        </Modal>

        <Alert isOpen={showAlert} onRequestClose={handleShowAlert}
            src='./images/message.png'
            title='EMAIL SENT'
            message="Kindly check your email to reset your password."
            messagePart=""
        />
        <AlertLoading isOpen={loading} onRequestClose={handleShowSpinner}/>
    </>
  )
}

export default Login