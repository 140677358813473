import React from 'react';
import '../css/Project.css';
import ProjectItem from './ProjectItem';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <ProjectItem
              src='/images/david.png'
              text='Explore the hidden waterfall deep inside the Amazon Jungle'
              label='Adventure'
              subtitle='IN PARTNERSHIP WITH SEE ME SCOTLAND'
              path='/projects/1/Adventure'
            />
            <ProjectItem
              src='/images/kham.png'
              text='Travel through the Islands of Bali in a Private Cruise'
              label='Luxury'
              subtitle='IN PARTNERSHIP WITH FEEDLONDON.ORG'
              path='/projects/2/Luxury'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
