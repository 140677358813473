import React from 'react';
import { Link } from 'react-router-dom';
import {Domain_Url} from '../mocks/Constants';

function CardItem(props) {
  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path}>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel'
              src={Domain_Url.Localhost+props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h1 className='cards__item__title'>{props.label}</h1>
            <h2 className='cards__item__subtitle'>{props.subtitle}</h2>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;
