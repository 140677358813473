import React from 'react'
import '../css/Play.css'

function PlayBanner() {
  return (
    <>
      <div className='play-banner-holder'>
        <div className='play-banner-tittle '>PLAY 6/45 + 1</div>
        <div className='play-banner-text'>Support a project for a chance to win</div>
        <div className='play-banner-price'>€ 1,000,000.00</div>
      </div>  
    </>
  )
}

export default PlayBanner