import React from 'react'
import '../css/Thankyou.css'
function Thankyou(props) {
  return (
    <div className='thankyou'>
      <p className='thanks-text-title'>{props.text}</p>
    </div>
  )
}

export default Thankyou